import axios from 'axios';
import { useCommonStore } from '@/store';
import { ElMessage } from 'element-plus';

export const insertToLaunchpad = async (
    candyMachineAddress,
    basicForm,
    collectionForm,
    detailForm,
    groupsForm,
    backtestStats,
    buyPointSettings,
    sellPointSettings,
    slPoint,
    tpPoint,
    mintFundWallet
) => {

    const userToken = window.$cookies.get('userToken');

    if (userToken) {

        const commonStore = useCommonStore();
        const webApiUrl = commonStore.webApiUrl;

        await axios({
            url: webApiUrl + '/launchpad/insert',
            method: 'post',
            data: {
                candy_machine_address: candyMachineAddress,
                trading_type: basicForm.type,
                trading_exchange: basicForm.exchange,
                trading_pair: basicForm.tradingPair.label.replaceAll(' ', ''),
                trading_pair_address: {
                    main: basicForm.tradingPair.address,
                    stable: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
                },
                leverage: basicForm.leverage,
                name: collectionForm.name,
                symbol: collectionForm.symbol,
                description: collectionForm.description,
                image: collectionForm.image,
                collection_address: collectionForm.collection_address,
                price: detailForm.price,
                mint_date: detailForm.mint_date,
                end_date: detailForm.end_date,
                twitter: detailForm.twitter_handle,
                discord: detailForm.discord_invite_code,
                website: detailForm.website_link,
                backtest_stats: backtestStats,
                buy_point_settings: buyPointSettings,
                sell_point_settings: sellPointSettings,
                sl_point_settings: slPoint,
                tp_point_settings: tpPoint,
                mint_fund_wallet: mintFundWallet,
                groups: {
                    og: groupsForm.og.hash_list,
                    wl: groupsForm.wl.hash_list,
                }
            },
            headers: {
                'Authorization': 'Bearer ' + userToken
            }
        }).then(response => {
            console.log(response.data);
        }).catch(error => {
            console.log(error);
        });

    } else {
        console.log('userToken is not set.');
    }

}

export const getLaunchpadStrategies = async () => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const data = await axios({
        url: webApiUrl + '/launchpad/get_all_strategies',
        method: 'get',
    }).then(response => {
        return response.data.Result;
    }).catch(error => {
        console.log(error);
    });

    return data;

};

export const mapLaunchpadGroupList = async (groupList) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const hash_list = await axios({
        url: webApiUrl + '/launchpad/map_group_list',
        method: 'post',
        data: {
            hash_list: JSON.stringify(groupList)
        },
        headers: {
            'Authorization': 'Bearer ' + userToken
        }
    }).then(response => {
        return response.data.Result;
    }).catch(error => {
        console.log(error);
        throw new Error(error.message);
    });

    return hash_list;

};

export const getLaunchpadGroupsList = async () => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const data = await axios({
        url: webApiUrl + '/launchpad/get_groups_list',
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userToken
        }
    }).then(response => {
        return response.data.Result;
    }).catch(error => {
        console.log(error);
    });

    return data;

}

export const getStrategiesName = async () => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const data = await axios({
        url: webApiUrl + '/launchpad/get_strategies_name',
        method: 'get',
    }).then(response => {
        return response.data.Result;
    }).catch(error => {
        console.log(error);
    });

    return data;

};

export const getStrategyByAddress = async (strategyAddress) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const data = await axios({
        url: webApiUrl + '/launchpad/get_strategy_by_address/',
        method: 'get',
        params: {
            candy_machine_address: strategyAddress
        }
    }).then(response => {
        return response.data.Result;
    }).catch(error => {
        console.log(error);
    });

    return data;

}

export const getAllStrategiesAddress = async () => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const data = await axios({
        method: 'get',
        url: webApiUrl + '/launchpad/get_all_strategies_address',
        headers: {
            'Authorization': 'Bearer ' + userToken,
        },
    }).then(response => {
        return response.data.Result;
    }).catch(error => {
        console.log(error);
    });

    return data

}

export const getMyStrategies = async () => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const data = await axios({
        method: 'get',
        url: webApiUrl + '/launchpad/get_my_strategies',
        headers: {
            'Authorization': 'Bearer ' + userToken,
        },
    }).then(response => {
        return response.data.Result;
    }).catch(error => {
        console.log(error);
        if (error.response.data.Status.Code == 403) {
            window.location.href = '/';
        }
    });

    return data

};

export const withdrawMintFund = async (strategyAddress, password) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const data = await axios({
        method: 'post',
        url: webApiUrl + '/launchpad/withdraw_mint_fund',
        headers: {
            Authorization: 'Bearer ' + userToken,
        },
        data: {
            candy_machine_address: strategyAddress,
            password: password
        }
    }).then(() => {
        return true;
    }).catch(error => {

        ElMessage({
            showClose: true,
            message: error.response.data.Status.Desc,
            type: 'error',
            duration: 5000
        });

        return false;

    });

    return data;

};