import axios from 'axios';
import { useCommonStore } from '@/store';
import { ElMessage } from 'element-plus';

export const sellPosition = async (semi_custodial_wallet_address) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const result = await axios({
        method: 'post',
        url: webApiUrl + '/trade/wallet/position/sell',
        headers: {
            'Authorization': 'Bearer ' + userToken
        },
        data: {
            wallet_address: semi_custodial_wallet_address
        }
    }).then(() => {

        return true;

    }).catch((error) => {

        ElMessage({
            type: 'error',
            message: error.response.data.Status.Desc,
            duration: 5000,
            showClose: true

        })

        return false;
    });

    return result;

}