import { 
    ComputeBudgetProgram, 
    Connection, PublicKey, 
    TransactionMessage, 
    VersionedTransaction, 
    sendAndConfirmRawTransaction 
} from "@solana/web3.js";
import { 
    Metaplex, 
    walletAdapterIdentity, 
    toMetaplexFile 
} from '@metaplex-foundation/js';
import { 
    generateSigner, 
    none, 
    percentAmount, 
    some, 
    sol, 
    dateTime, 
    transactionBuilder, 
    createSignerFromKeypair, 
    keypairIdentity 
} from '@metaplex-foundation/umi';
import { createUmi } from "@metaplex-foundation/umi-bundle-defaults";
import { walletAdapterIdentity as umiWalletAdapterIdentity } from '@metaplex-foundation/umi-signer-wallet-adapters';
import { nftStorageUploader } from '@metaplex-foundation/umi-uploader-nft-storage';
import { 
    create, 
    fetchCandyGuard, 
    fetchCandyMachine, 
    getMerkleRoot, 
    mintV2, 
    mplCandyMachine, 
    updateCandyGuard 
} from '@metaplex-foundation/mpl-candy-machine';
import { setComputeUnitLimit, setComputeUnitPrice } from '@metaplex-foundation/mpl-toolbox';
import { 
    TokenStandard, 
    createProgrammableNft, 
    mplTokenMetadata 
} from '@metaplex-foundation/mpl-token-metadata';
import { createAddConfigLinesInstruction } from '@metaplex-foundation/mpl-candy-machine-core';
import { useWallet } from "solana-wallets-vue";

import { useCommonStore } from '@/store';
import _ from 'lodash';
import axios from 'axios';
import bs58 from 'bs58';
import dotenv from 'dotenv';
import { ElMessage } from 'element-plus';

import { connectWallet } from './useCommon';
import { getLaunchpadGroupsList, mapLaunchpadGroupList } from './useLaunchpad';
import { uploadStrategyImage, uploadStrategyMetadata } from './useStorage';

// 可讀取 .env 檔案
dotenv.config();

const getMetaplex = () => {

    const wallet = connectWallet();

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new Connection(clusterUrl, "confirmed");

    const metaplex = Metaplex.make(connection)
        .use(walletAdapterIdentity(wallet));

    return {
        metaplex: metaplex,
        connection: connection
    };

}

export const getUmi = () => {

    const wallet = connectWallet();

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const umi = createUmi(clusterUrl, "confirmed")
        .use(umiWalletAdapterIdentity(wallet))
        .use(nftStorageUploader({
            token: process.env.VUE_APP_NFT_STORAGE_API_KEY,
        }))
        .use(mplTokenMetadata())
        .use(mplCandyMachine());

    return umi;

}

const chunkArray = (arr, size) => {
    return arr.length > size
        ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)]
        : [arr];
}

export const executeTransactions = async (txs, config) => {

    const wallet = connectWallet();
    const { connection } = getMetaplex();

    const latestBlockhash = (await connection.getLatestBlockhash()).blockhash;
    const signedTxs = await wallet.signAllTransactions(
        txs.map((tx) => {
            tx.recentBlockhash = latestBlockhash;
            tx.feePayer = wallet.publicKey;
            if (config?.signers) {
                tx.partialSign(...(config?.signers ?? []));
            }
            return tx;
        })
    );

    const batchedTxs = chunkArray(
        signedTxs,
        config?.batchSize ?? signedTxs.length
    );

    const txids = [];
    for (let i = 0; i < batchedTxs.length; i++) {
        const batch = batchedTxs[i];
        console.log(batch);
        if (batch) {
            const batchTxids = await Promise.all(
                batch.map(async (tx) => {
                    try {
                        const txid = await sendAndConfirmRawTransaction(
                            connection,
                            tx.serialize(),
                            config?.confirmOptions
                        );
                        return txid;
                    } catch (e) {
                        console.log(e);
                        return null;
                    }
                })
            );
            txids.push(...batchTxids);
        }
    }
    return txids;

}

const uploadMetadata = async (config, total_supply, createProcess) => {

    const umi = getUmi();
    const collectionMintSigner = generateSigner(umi);
    const collectionAddress = collectionMintSigner.publicKey.toString();
    console.log('collectionAddress', collectionAddress);

    // upload image to firebase
    const imgUri = await uploadStrategyImage(config.image, collectionAddress);
    console.log('imgUri', imgUri);

    if (createProcess.value == 0) {
        createProcess.value += 1;
    }

    const metadataUploadResult = await uploadStrategyMetadata(
        {
            name: config.name,
            symbol: config.symbol,
            description: config.description,
            image: imgUri,
            attributes: config.attributes,
            properties: {
                files: [
                    {
                        uri: imgUri,
                        type: "image/png",
                    }
                ]
            }
        },
        collectionAddress
    )

    const metaplexFilesUri = [];
    let collectionUri = null;

    if (metadataUploadResult) {

        const baseUrl = 'https://api.mimirlab.xyz';

        for (let i = 0; i < total_supply; i++) {
            const uri = `${baseUrl}/metadata/${collectionAddress}/${i + 1}.json`;
            metaplexFilesUri.push(uri);
        }

        collectionUri = `${baseUrl}/metadata/${collectionAddress}/collection.json`;

    }

    console.log(metaplexFilesUri);

    if (createProcess.value == 1) {
        createProcess.value += 1;
    }

    return {
        image: imgUri,
        metadata: metaplexFilesUri,
        collection: collectionUri,
        collectionMintSigner: collectionMintSigner
    }

}

const createCollection = async (nftMint, config, collectionUri) => {

    const umi = getUmi();

    const result = await transactionBuilder()
        .add(
            setComputeUnitLimit(umi, { units: 250_000 })
        )
        .add(
            setComputeUnitPrice(umi, { microLamports: 1500000 })
        )
        .add(
            createProgrammableNft(
                umi,
                {
                    mint: nftMint,
                    name: config.name,
                    symbol: config.symbol,
                    uri: collectionUri,
                    sellerFeeBasisPoints: 0,
                    isCollection: true,
                }
            )
        )
        .sendAndConfirm(
            umi,
            {
                confirm: {
                    commitment: "finalized"
                }
            }
        );

    console.log('Collection Mint Tx: ', result);

    return {
        address: nftMint.publicKey.toString(),
    }

}

const insertItemsToCandyMachine = async (cmAddress, collectionName, metadataUri, detailForm) => {

    const { sendTransaction } = useWallet();

    const items = [];
    for (let i = 0; i < metadataUri.length; i++) {

        const metadataUrl = metadataUri[i];

        items.push({
            name: `${collectionName} #${i + 1}`,
            uri: metadataUrl,
        });

    }

    console.log(items);

    const { metaplex, connection } = getMetaplex();

    try {

        const candyMachine = await metaplex.candyMachines().findByAddress({
            address: cmAddress,
        });

        const candyMachineProgram = metaplex.programs().getCandyMachine();
        const authority = metaplex.identity();

        for (let i = detailForm.complete_supply; i < items.length; i += 3) {

            console.log('detailForm.complete_supply', detailForm.complete_supply);
            console.log('Insert Items', items.slice(i, i + 3));

            const instructions = [
                ComputeBudgetProgram.setComputeUnitLimit({
                    units: 50_000
                }),
                ComputeBudgetProgram.setComputeUnitPrice({
                    microLamports: 1000000
                }),
                createAddConfigLinesInstruction(
                    {
                        candyMachine: candyMachine.address,
                        authority: authority.publicKey,
                    },
                    { 
                        index: detailForm.complete_supply, 
                        configLines: items.slice(i, i + 3) 
                    },
                    candyMachineProgram.address
                )
            ];

            const latestBlockHash = await connection.getLatestBlockhash();

            const messageV0 = new TransactionMessage({
                payerKey: authority.publicKey,
                recentBlockhash: latestBlockHash.blockhash,
                instructions: instructions
            }).compileToV0Message();
    
            const transaction = new VersionedTransaction(messageV0);
            
            const txid = await sendTransaction(
                transaction,
                connection,
                {
                    skipPreflight: true
                }
            );
            console.log(txid);

            const result = await connection.confirmTransaction(txid, "confirmed");
            console.log(result);

            if (result.value.err) {
                throw new Error('Failed to insert items to candy machine, please try again.');
            }

            if (detailForm.complete_supply + 3 < items.length) {
                detailForm.complete_supply += 3;
            }

        }

        return candyMachine.collectionMintAddress.toBase58();

    } catch (e) {

        // 避免失敗而設置的 retry
        console.log(e);
        throw new Error(e.message);

    }


}

// mint 2 張 nft 到項目錢包當中
const mintToSnftWallet = async (candyMachineAddress) => {

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const umi = createUmi(clusterUrl, "confirmed")
        .use(mplCandyMachine());

    const secret = process.env.VUE_APP_SNFT_WALLET_PRIVATE_KEY;
    const secretKey = new Uint8Array(JSON.parse(secret));
    const keypair = umi.eddsa.createKeypairFromSecretKey(secretKey);
    const keypairSigner = createSignerFromKeypair(umi, keypair);

    umi.use(keypairIdentity(keypairSigner));

    while (true) {

        const candyMachine = await fetchCandyMachine(umi, candyMachineAddress);

        const mintedItems = candyMachine.items.filter(item => item.minted);
        if (mintedItems.length == 2) {
            break;
        }

        if (candyMachine.items.length == candyMachine.data.itemsAvailable) {

            const candyGuard = await fetchCandyGuard(umi, candyMachine.mintAuthority);

            try {

                const nftMint = generateSigner(umi);

                const tx = await transactionBuilder()
                    .add(setComputeUnitLimit(umi, { units: 500_000 }))
                    .add(setComputeUnitPrice(umi, { microLamports: 800000 }))
                    .add(
                        mintV2(umi, {
                            candyMachine: candyMachine.publicKey,
                            nftMint: nftMint,
                            collectionMint: candyMachine.collectionMint,
                            collectionUpdateAuthority: candyMachine.authority,
                            candyGuard: candyGuard.publicKey,
                            tokenStandard: TokenStandard.ProgrammableNonFungible
                        })
                    )
                    .sendAndConfirm(
                        umi,
                        {
                            confirm: {
                                commitment: "finalized"
                            }
                        }
                    );

                console.log('Mint Tx: ', bs58.encode(tx.signature));

            } catch (e) {
                console.log(e);
                throw new Error('Failed to simulate minting.');
            }

        } else {
            console.log('Waiting for candy machine to be ready...');
            await new Promise(r => setTimeout(r, 2000));
        }

    }

};

const createMintFundWallet = async (candyMachineAddress, password) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const wallet_address = await axios({
        method: 'post',
        url: `${webApiUrl}/launchpad/create_mint_wallet`,
        headers: {
            Authorization: `Bearer ${userToken}`
        },
        data: {
            candy_machine_address: candyMachineAddress,
            password: password
        }
    }).then(response => {
        return response.data.Result;
    }).catch(error => {
        ElMessage({
            message: 'Failed to create mint fund wallet: ' + error.message,
            type: 'error',
            showClose: true,
            duration: 5000
        });
        return null;
    });

    return wallet_address;

};

// update candy guard
const updateGuardTime = async (start, period_hour) => {

    if (start) {
        var start_time = new Date(start);
    } else {
        var start_time = new Date();
    }

    const end_time = new Date(start_time);
    end_time.setHours(end_time.getHours() + period_hour);

    return {
        start_time: start_time,
        end_time: new Date(end_time)
    };

}

const updateGuard = async (umi, candyMachineAddress, detailForm, groupsForm, destination) => {

    const candyMachine = await fetchCandyMachine(umi, candyMachineAddress);
    const candyGuard = await fetchCandyGuard(umi, candyMachine.mintAuthority);

    const groups = [];
    let start = new Date();
    start = new Date(start.setHours(start.getHours() + 24));
    detailForm.mint_date = start;

    for (const key of Object.keys(groupsForm)) {

        const info = groupsForm[key];

        if (info.name == 'wl' && info.hash_list == '') {
            continue;
        }

        const label = info.name;

        if (info.name == 'og' || info.name == 'wl') {

            const { start_time, end_time } = await updateGuardTime(start, info.period);
            console.log('start_time', start_time);
            console.log('end_time', end_time);

            if (info.name == 'og') {
                const groupsList = await getLaunchpadGroupsList();
                var hash_list = groupsList.og;
                var group_id = 1;
            } else {
                var hash_list = info.hash_list.split('\n');
                hash_list = await mapLaunchpadGroupList(hash_list);
                var group_id = 2;
            }

            groupsForm[key].hash_list = hash_list;

            if (info.name == 'og') {
                var redeemedAmount = info.limit + 2;
            } else if (info.name == 'wl') {
                var redeemedAmount = info.limit + 12;
            }

            groups.push({
                label: label,
                guards: {
                    solPayment: some({
                        lamports: sol(info.price),
                        destination: new PublicKey(destination),
                    }),
                    startDate: some({ date: dateTime(start_time.toISOString()) }),
                    endDate: some({ date: dateTime(end_time.toISOString()) }),
                    allowList: some({ merkleRoot: getMerkleRoot(hash_list) }),
                    redeemedAmount: some({ maximum: redeemedAmount }),
                    mintLimit: some({ id: group_id, limit: 1 })
                }
            });

            start = end_time;

        } else {

            groups.push({
                label: label,
                guards: {
                    solPayment: some({
                        lamports: sol(info.price),
                        destination: new PublicKey(destination),
                    }),
                    startDate: some({ date: dateTime(start.toISOString()) }),
                    mintLimit: some({ id: 3, limit: 1 })
                }
            });

        }

    }

    const update_tx = await updateCandyGuard(
        umi,
        {
            candyGuard: candyGuard.publicKey,
            guards: {},
            groups: groups
        }
    );

    const result = await update_tx.sendAndConfirm(umi, {
        confirm: {
            commitment: "confirmed",
        }
    });

    console.log('Update Guard Tx: ', bs58.encode(result.signature));

};

export const createCandyMachine = async (collectionForm, detailForm, groupsForm, wallet_password, createProcess) => {

    const config = _.cloneDeep(collectionForm);
    console.log('config', config);

    const detail_config = _.cloneDeep(detailForm);
    console.log('detail_config', detail_config);

    try {

        // 將 nft collection 及 items metadata 上傳至 ipfs
        // total_supply + 2 是因為要多上傳兩張 nft 到項目錢包當中
        const { image: imgUri, metadata: metadataUri , collection: collectionUri, collectionMintSigner } = await uploadMetadata(
            config, 
            detail_config.total_supply + 2, 
            createProcess
        );

        console.log('imgUri', imgUri);
        console.log('metadataUri', metadataUri);
        console.log('collectionUri', collectionUri);

        config.image = imgUri;

        // 當 createProcess.value == 2 時，代表完成上傳至 ipfs 的動作，接著進行創建 nft collection
        let collectionMint;
        if (createProcess.value == 2) {

            // 創建 nft collection
            collectionMint = await createCollection(collectionMintSigner, config, collectionUri);
            console.log('collectionMint', collectionMint);

            window.$cookies.set('collectionMint', JSON.stringify(collectionMint));
            createProcess.value += 1;
            await new Promise(r => setTimeout(r, 1000));

        } else if (createProcess.value > 2) {

            collectionMint = window.$cookies.get('collectionMint');
            console.log('collectionMint', collectionMint);

        }

        // 當 createProcess.value == 3 時，代表完成創建 nft collection 的動作，接著進行創建 candy machine
        let cmAddress;

        // 創建 candy machine
        const wallet = connectWallet();
        const umi = getUmi();

        if (createProcess.value == 3) {

            // 給定 candymachine 一個隨機的 mint keypair
            const candyMachine = generateSigner(umi);

            // 創建 candy machine
            // total_supply + 2 是因為要多上傳兩張 nft 到項目錢包當中
            const create_tx = await create(
                umi,
                {
                    candyMachine,
                    collectionUpdateAuthority: wallet,
                    collectionMint: collectionMint.address,
                    tokenStandard: TokenStandard.ProgrammableNonFungible,
                    ruleSet: new PublicKey("eBJLFYPxJmMGKuFwpDWkzxZeUrad92kZRC5BJLpzyT9"),
                    isMutable: true,
                    itemsAvailable: detailForm.total_supply + 2,
                    sellerFeeBasisPoints: percentAmount(parseFloat(detailForm.royalties), 2),
                    hiddenSettings: none(),
                    creators: [
                        {
                            address: umi.identity.publicKey,
                            verified: false,
                            percentageShare: 92,
                        },
                        {
                            address: process.env.VUE_APP_LAUNCHPAD_ADDRESS,
                            verified: false,
                            percentageShare: 8,
                        }
                    ],
                    configLineSettings: some({
                        prefixName: "",
                        nameLength: 32,
                        prefixUri: "",
                        uriLength: 200,
                        isSequential: false,
                    })
                }
            );

            const cm_result = await create_tx.sendAndConfirm(umi, {
                confirm: {
                    commitment: "finalized"
                }
            });

            console.log('Candy Machine Tx: ', cm_result);
            console.log('candyMachine', candyMachine);

            cmAddress = new PublicKey(candyMachine.publicKey);
            window.$cookies.set('cmAddress', cmAddress.toBase58());
            createProcess.value += 1;
            await new Promise(r => setTimeout(r, 1000));

        } else if (createProcess.value > 3) {

            cmAddress = new PublicKey(window.$cookies.get('cmAddress'));
            console.log('cmAddress', cmAddress);

        }

        // 將 items 插入 candy machine
        if (createProcess.value == 4) {

            const collectionAddress = await insertItemsToCandyMachine(cmAddress, config.name, metadataUri, detailForm);
            console.log('collectionAddress', collectionAddress);
            collectionForm.collection_address = collectionAddress;

            await new Promise(r => setTimeout(r, 1000));
            createProcess.value += 1;

        }

        let mintFundWallet;

        if (createProcess.value == 5) {

            // Mint 2 張 nft 到項目錢包當中
            await mintToSnftWallet(cmAddress);
            await new Promise(r => setTimeout(r, 1000));

            mintFundWallet = await createMintFundWallet(cmAddress, wallet_password);
            console.log('mintFundWallet', mintFundWallet);

            if (!mintFundWallet) {
                throw new Error('Failed to create mint fund wallet. please try again.');
            }

            // update candy guard
            await updateGuard(
                umi,
                cmAddress,
                detailForm,
                groupsForm,
                mintFundWallet
            );

            createProcess.value += 1;

            window.$cookies.remove('imgUri');
            window.$cookies.remove('metadataUri');
            window.$cookies.remove('collectionUri');
            window.$cookies.remove('collectionMint');
            window.$cookies.remove('cmAddress');

        }

        return {
            candyMachineAddress: cmAddress,
            image: imgUri,
            mintFundWallet: mintFundWallet
        };

    } catch (e) {

        console.log(e);

        ElMessage({
            message: e.message,
            type: 'error',
            showClose: true,
            duration: 5000
        });

        return {
            candyMachineAddress: null,
            image: null,
            mintFundWallet: null
        };

    }

}

export const getCandyMachine = async (candyMachineAddress) => {

    const { metaplex } = getMetaplex();

    try {

        const candyMachine = await metaplex.candyMachines().findByAddress({
            address: new PublicKey(candyMachineAddress),
        });

        return candyMachine;

    } catch (e) {
        console.log(e);
        return null;
    }

};

export const withdrawCandyMachine = async (item) => {

    const { metaplex } = getMetaplex();

    const candyMachine = await getCandyMachine(item.candy_machine_address);

    if (candyMachine) {

        const authority = metaplex.identity();

        try {

            await metaplex.candyMachines().delete({
                candyMachine: candyMachine.address,
                candyGuard: candyMachine.candyGuard.address,
                authority: authority,
            });

            item.canWithdraw = false;

        } catch (e) {
            console.log(e);
        }

    }

    item.isButtonLoading = false;

}