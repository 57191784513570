import { Metaplex, walletAdapterIdentity } from "@metaplex-foundation/js";
import { walletAdapterIdentity as umiWalletAdapterIdentity } from "@metaplex-foundation/umi-signer-wallet-adapters";
import { TokenStandard, mplTokenMetadata } from "@metaplex-foundation/mpl-token-metadata";
import { mplCandyMachine, fetchCandyMachine, mintV2, fetchCandyGuard, getMerkleRoot, route, getMerkleProof } from "@metaplex-foundation/mpl-candy-machine";
import { Connection, PublicKey, clusterApiUrl } from "@solana/web3.js";
import { useWallet, useAnchorWallet } from "solana-wallets-vue";
import { createUmi } from "@metaplex-foundation/umi-bundle-defaults";
import { generateSigner, transactionBuilder } from "@metaplex-foundation/umi";
import { setComputeUnitLimit, setComputeUnitPrice } from "@metaplex-foundation/mpl-toolbox";
import { some } from "@metaplex-foundation/umi";
import { ElMessage } from "element-plus";

// const clusterUrl = "https://necessary-old-log.solana-devnet.quiknode.pro/2f39a656ad6bdaa738519f721b4b9cd09a188548";
const clusterUrl = "https://bold-maximum-fog.solana-mainnet.quiknode.pro/fb924ecc1964a15e0734bca9b247b52e83e2dbba/";

const connectWallet = () => {

    const wallet = useAnchorWallet();
    return wallet.value;

}

const getMetaplex = () => {

    const connection = new Connection(clusterUrl, 'confirmed');
    const wallet = useWallet();

    const metaplex = Metaplex.make(connection).use(
        walletAdapterIdentity(wallet)
    );

    return metaplex;

}

export const getBackpackCandyMachine = async (candyMachineId) => {

    const metaplex = getMetaplex();

    const candyMachine = await metaplex.candyMachines().findByAddress({
        address: new PublicKey(candyMachineId)
    });

    return candyMachine

};

const getUmi = () => {

    const wallet = connectWallet();

    console.log(mplTokenMetadata);

    const umi = createUmi(clusterUrl, "confirmed")
        .use(umiWalletAdapterIdentity(wallet))
        // .use(mplTokenMetadata())
        .use(mplCandyMachine());

    return umi;

}

export const mintBackpackNft = async (candyMachineId, group_label, mintProcess, showMintDialog) => {

    try {

        showMintDialog.value = true;

        const umi = getUmi();

        const candyMachine = await fetchCandyMachine(umi, new PublicKey(candyMachineId));
        console.log(candyMachine);

        const candyGuard = await fetchCandyGuard(umi, candyMachine.mintAuthority);
        console.log(candyGuard);

        const nftMint = generateSigner(umi);

        console.log(group_label);

        if (group_label == 'wl' || group_label == 'og') {

            let allowList = [];
            let mintArgs = {};

            if (group_label == 'wl') {

                allowList = [
                    '6kzjSiU8q2Sf7basGp3omoxUNR9NBQx3seDEykxDzATn',
                    'Le7MqTQ7ghXbnvZd1HqhqNpnnPPyNhBsrhSfdrPMsyp',
                    'BibJJP2cbMHUpdMEJpHh9747hVaey1sXkZvPXgK3Z5YE',
                    '4p5ovUrwdm8FbHvLK7ciX9kFdsJM41bwz9KWzJvYXDqb',
                    'J21PApnQ6XL3yQuBNSzQscDj9XZ9yHB5DkHtrYdecot1',
                    '4nwjCd9wQk1NdwgJrZDfvAutpBUZaGMCJ3pGMyeBJWXf',
                    '36HCUniyA864vjmKMZDj9VuKrcT79DwAaeeXZ7BxxiQE',
                    '6wrZSirMYjGYiUgSqRV8vJCizjY1RXGGxZbvpPodkq7U',
                    '8p9Tr1w3tYKHF9GasWWYryzSbtgWvaCYTJ8UALE2Ky3A',
                    'EYv4mnXtZEJNvp1qGjDcdS7JC8ivHYN6kG5ArnoDKesa',
                    'Ah6QKv4t4SoxbEmrperbHvVJCXa8kWMoNTzEHxvMmzrw',
                    'AzScnTQ7sLSFL2secPjUhATxCuUScCxVq5RwmyiWm18P',
                    'G5FWNn6x1pJZ4C4pDTGtYDwRaTXBMjLK3ZMnrVyehfVC',
                    'UhE5Dcc8yhpjtaYJU6NMchUcvmAGGfuzMKPsCreXVPA',
                    '9JurQZXBywzaRDL2UUg7GWF2A5RphV1tmq4EUAxtsqpf',
                    'G5WpFHtBuQx7jjVLMAuBvoUeRbRS2etshfFpk1uxhfX9',
                    'B9sPD93VdMwP5QdqvQUju7AamE8DYxksXWeTrNYpQr6g',
                    'J3QffeGb1PPo6jtWCdkSPDTiAdGaSPgZ53joVqw4cRw5',
                    'FYTtmUW9pxyEPYPPMVeev2Vod2sFqbDVMojaszsivHMr',
                    'GgnrSyzUcRE2PJxTQu8ExEoTLq6cNAq9goiCxCQaneyK',
                    'GAD9fPC7BZZk8esqEaqAHTWwAE3ENCwbPGzSrYGetGh8',
                    'DUrTAZwDsu329E2E7jfRGQATwkXWTgE8M67pyxVCncLK',
                    '4AUFjnMHpdJDccuM81WGh23F8w4pjxWw8yMPixxbzDpu',
                    'FHLQ2dWGZxB3ohBynUh5aza77SqvtYD86WJP4SQYkp3p',
                    '4E5ht2tY4S8wbAaNYbj82Q1q9iGMvL7w9TkeSAQ1hSbc',
                    'Co96yVdVnHy8bhH8xbba3y6JKFKiu9Rrd2QQEYhsYshw',
                    'Fitz9JHqfD3NT9FUqiN1kh31M7gYKTshRBcawq6gjDS1',
                    '8Z4NbBWkwLx1NTzLnp3ZmjLtk1ExvH2PFb2JZvY22bDf',
                    '5Bi3jMZvar5P1CJNK4ng1AWDNxXCHcAUPXhDVL8FXxLY',
                    '3MxtkGuVj2W4kRUdxzNdTW7kRCjqsdZqJaHT1YqR2FfW',
                    'FP7nSHHFByaS9xkJXSqiN4BgPEKm1LjXpMRAeMSiz1Bq',
                    'cuFM92DZWVEyQsFKeCdxutnT4vDS3PGBEVzmzYaypFc',
                    '7xX8VNixvsg6NiQzrLVPdjJ9yNXFoQDwSDnJNjdSRtrb',
                    '2xDcmnUAgVUTbkZYgo3mxddUe1ty4yYSRwATm1HBAVyx',
                    'CkWZvnmG6pFZ9F9hUBVHewfqj7HWKFPD371jwMDKdhgF',
                    '6haWWSH9fVsuNmKbseStNR6tgQscp2dn1n3nwGUoFAFW',
                    '66xX9haSaxALCCZBUfeXqcDwYiE267fLhQECQkg7twL6',
                    '975eS65pjDnfWk4Jmm5J1rQ9b9usuDczXG3nEEhtpPGU',
                    '4ZShhHsphF6UHg8bnawCvBfM5LDr3dxeW5dPZ8t1Uv9L',
                    '6mcqMhX3wWBrgkzsogKw7KaLFhhJsqRtxPbeMPAaRfjW',
                    '4Ag3arHU7vX8GLk7f3ab5Xm93xYaXpdJ8kLzFewg34mi',
                    'UcdPvZzTc4ae47PE5KJBk77cJCWTksRPiZFetSsN4zn',
                    '3h5UJUoEcirpyMCcT9oaLoBtRpcVC5ArNsgoKgkMJh9u',
                    'qSUAEgyJvgY7EFbEECZ9FqvKP61dgerFzZyDHdDhZWM',
                    '9HSvmjGWvy92e9VgJJYuKf4piz2hJbtijyw3PazFGnkv',
                    '8oDgDnf3FyrFtz2Sg1J7ACEJBVQn6LAo5KhxvfiBmGxV',
                    'Bmh4jWKioNYEGKZkqCcP36S3qD1SAsNuwPsMcRdWVdzg',
                    '5Xe8MkNi6puytMbd4NTuBxCcZFbc72sAvs2xJvTQq4ZH',
                    'BSKNQz2AaBBSytuhRpKSe8pGZCmA6uUVaEv2SQDfFBf2',
                    'cXxx1hEbGXh3hQUwPDNvpTxoDcK7RLM3TkPfu866NT4',
                    'GqrjhDULUJEs9uYgEBXBjzCgv7SK5JLF8PCVUPG91TPf',
                    'GwhET7CVrVhHNtsZarTRyazCxfDhNXgULRZiUKFJvVtm',
                    'DdXUi5BE7sTkQmpZeoiiyVwAm2a1WMYRoYpSQxZPEyZd',
                    'EpvRir7VHgmFgTRHPrpfm4VBDN5yJpojWWgn2LtmHnda',
                    '5R7PmYaEoNUCYvcDSnK5BAL6MFsJmtbch3aQs5Lyp7ZD',
                    '7e3o2nbMkRhcSJ5itcZSGbBu4mAevt1Z2ZPiB2ghJEmG',
                    'DedFYT1MSt6YmjSVeALqj9pGbZefdW4dmJGKkZCK7TAx',
                    '5HWSqMsdZLLFVgWWFRHasni1ojZFcjbaxUBYnRXAvvDE',
                    'HwbXL3q9Sr7HHJXYQkLTZ8Le7e1HR576Dwwo5hfSsdgs',
                    '2inWtye7JmRHZYw6nyGEu8AvW9DgRi8D6j4EJMvTL4f5',
                    '5GV7Edsm5nfv6faf57F4ycdPtF6AmViy4aBHczZLhEaa',
                    'EV8FaEAtzFbh2cpgjZTVQYHJhcrEL5yHPu7rnTi8U3Tp',
                    '6nwfRLXiAVKKHpSQ2pLDJm6AFEPTcCuWhEKMaebMZ2Zd',
                    'DWwXs7GFj8UxjFKySysiWuhE4RjYxk5jDgwth8uQ2bF4',
                    'CbMAXitEa23My9CnCaeUGTPhR9gDTeQSiHWKCnp8qjBh',
                    '81eiK8EsjTWKqZezjp4YD3MRdTt7bSqQ3FYt4dxtWrVG',
                    '7Cw9VC9Xo8k2Nz1ZBSWM6BghdBd9vw3EWqKZ2sBpfPwb',
                    'HKiNeY14zzN5y9pVh4MQqphopzbV6cyTkPtPyC17XtHt',
                    'HgSSwAQmq9KJx9YquDYUf8sJz6779iNcsFkwS4CCq3rw',
                    'Gp75qeW5PTNCPFrmUKF25z3AeqeAt3ZuAxoS6CPMg38H',
                    '9Z4yiG4zw1BKeWAmmvkwEQWJR7a3dmZm9g2PVfo32tSD',
                    'FXPMEQmUuL9PLYg4AW2DgciG6cur1hz2UEnm36mtB5YT',
                    '5wP3T6DhVSbkxvsKyFgNqx7HT2WonxsuCrMk2wGXPxnX',
                    'CbEDutJy6NKkWZw8MV2dBjoJa4ZqrWeF2STXRqZmDqrn',
                    'H36JFxy1V7CdPm9f7hH8VSFGGX4tK9RXn1ZSCY1iNgXX',
                    'A4drw5qpexwqyrGzTEjBhxvD8uYq667FAn9bhb6Tbn1A',
                    'EyXHZhrWnoGnckzXZApKNSo4HjrPxfiwSewxbZV5zUYK',
                    'HNsiDebuT7J6AbDvLi8q1YGMF3hiSB7r16tApCAP7qU6',
                    '52L1L4fczoQRxvm5Nrt33eDmgEEJfBNSE92HY9TMgMCD',
                    '3sTDgx7Dpj1HPv5kX2ViExEBJSoPfZqLMvA523vRRGsV',
                    '3Gu4H3defC3akuuNwVoDMjknKS81HZzJ6dLxMGGe2CVv',
                    'hN5nbu2yB4okN5PTqccUSUcsLifZ5eid2Zzxs6uzhdp',
                    'GPh1BdHjTF1Ro2BZsMpZdAo9FEsKF6mXEaTZweH9hAaH',
                    '4xivY6GXQaiHTgLMQT3g57MtMdS9s6wpfSKhA14D9Ktr',
                    'Bfgk2fL7QgfUnTtbs98irDBw9m35Pn4KuDLZEdP1sfkA',
                    'EA1ESKW994csKkZtjeMtrAMmw2X7FXVcsXFYg1go6mBk',
                    '7u2x89wus5s9gAyDXv9Gh6JBgdM6yu7dWuaizY5ubRbC',
                    'Hjh7Snt4vmcch8DsukrSnf6SZqvAdMwP4G283JzUL1UC',
                    'AMfpA456fXdn5zPHJnp6ybNFrqLyyMTWrsAH7tqNMefb',
                    '6oF4bmsEuZqBiMpp7E14ttjvASSAJv81f9n9sVb3trbD',
                    '6dXn1svEDYJuHVkCM5XeARoyHNet4GQ2bVj3cJaedDcm',
                    '25H8zCFdgdUr5wRtMHCkFZw78kw5wyNMcg5wqwuK8TGR',
                    'Hkj1qNn3iKf7s5waWomSr8WdFEAKRYM2RFQdYcXeEyJM',
                    '888yAqMzcGvX2Sp35h9VciWjmHrWC5m3TT3ebbEMNN2i',
                    '4JkzWMgsAS4RwTcKLQui6uVU1RUwJnk78LkffN18AQec',
                    '9zL1ksYP9Jk3o5fMpudhRezqg6UunvPCnxR7kbFwnm4P',
                    'B1LfhE9mZWX35tvp9c4ALEdBKNU3QsNeMX5TS1qU7jTf',
                    'CwtKh6xyDhqb1ufhdxxP2nyCHfJ13b6HfCYwFVxTEexf',
                    '7eH8koqbqrxJJjEKuV2VqHZgE8xhVFJpPymPRNPJEo4C',
                    'CRafUEgvxVzW3aobLELQnBrn5yjky3xysSqw3AY1XFBK',
                    '8roYyayE2s7vuXVCpSpffZyuZm5G46jYvn6gbBwHhBhb',
                    'J2GYBfMkgr6mFVmmmbSraJPXeBqAHcMb6nNXWmTKS7QT',
                    'BXmh7yoKncxhT15GgozrURd1T2qEX1eBtDwgLm5Qa919',
                    '2ZaQrDLhDBniCeQDowU6dKrHNQuaduXUpk2TmMpuQ6mG',
                    'Cbe5ZKbYp1x6RY3DAzfqP9edpm7a4JhCXm4eymWGaR9m',
                    '6mAtTnzysfd77FMMgCqB1C3VoQCLmH4LUzb767YmUx3i',
                    '78WXN4cz2s91i3hrw5SykHEN7cqaSjziY5E4rsaP84PS',
                    'BwMh3AxKdWNpdNZXK4aWEAUovM74RBPMbbQoFe5GTAJ8',
                    '5tQL9oWsA25w3wWp3rfztRcFi4dCfq2CCrxSomZvvXmq',
                    '5XXhBG18ghYpM1r5jxczQ9H2km2u1J15MkFA6sEAYgSX',
                    'qic5XZPs5qgbFWuUSuP5QoU8awZ6PdhhhbD79eyLC18',
                    'H9TdGnenPotWuou39vP1RjbY4sirfmJoF81xY91QckF8',
                    'GK9he5TXEPtVyWaBn4oSPS1VzzYGn4daoVu6xCVbeACp',
                    '2PSsfB8PntzYa94LW6v5mCKoNs4vRdspH9BC2EhD3P7U',
                    '8VpLfViaNB4BEgmLbhqhMqu7Yhkzyq6mzKnau4h1eQtw',
                    '7okf8rBmNFsp9VFXiYGMwuMXGvrX7NmPjVsVJdJkoGs3',
                    'C5fM3Z3Qs6r1DsEnXjzJ5o7FkNRUqJC2jhB17U6Ch66f',
                    'E1tBsL78hfSePAKEpGYmwiL9qmWBXr4vQ557Wez3KmsM',
                    'BnofW8ncoLthFp4Xu3KFDoSRy5KNEcd8Ycvi9H97Vz6y',
                    '3cirK9VbH7DFNRHE4mYjprYjXB74jYSyjboKyUy6EiTw',
                    'Y29FAeX1WbmSCB2r4j8Pxb9qRZpMkBYq1GRksFwda5S',
                    'B8P1zWGi54aMonYPoMThffXtzNtbtmAWtADhU5BQ6uHX',
                    'CLF41nTFcXBhRQC7h7ZB6iYKn7Fx739H6RxsD16bDVXo',
                    '26PmEDHTgjCP9JBESy4nZB4h3phMZqKWn9t3ZouGBVAG',
                    'AoytnAVHHQYqQDW8PM4juD5XNChNVeaPgdvD65tLPdXW',
                    'ASpmg6bEJfBPfMJuYKDwAYAxSRCxCCdFQ9Fpm8oiDqaV',
                    'FSWRxxb8rXUgNouYoZhcB6FadFLmbWS5LnX6aTKwHMQM',
                    'ArSR5L1zaYxzCYihfXT6tqqXWe4EmxnhU71GhPUPEQoj',
                    '5PwbEpgQRvsbEiQTEUaUiubtf9FMhTimsE4nCzU7wzrZ',
                    '4zsRMD7wFDdZZDiXPkuUab2HyVjYM2MmVBmvTfYsUiuK',
                    'GuUduxukTR2N2R8Ez7SFBPq37mtiNqxE8PexQyopGs94',
                    'Bj7RXnGphADV8dvgr4VZT61XaFrZhdh9Hf9QL6mLTywH',
                    '7jCufEp9x5qNoHa6GA1ed5Lj3NRvx366WoHWUKkjokch',
                    'EwP7uRpMf43E7WBBmUEbZCVEhYeNQZdSJqu9YoNn5WqC',
                    '8QZCywaxXodKwJm9GX25P3VFkAwvRFVfmTkxikcVQe4R',
                    'DNcV1Ni7RG6WwfytLJ8bRse5jgZbCVthumqRFSqBkpfM',
                    'AZexiw6X7ZBi4KEqEeDdMo8o25o5ZaX57qC9VFbVN78t',
                    'D1XKamuswvkytq2jeieGCXpLAcMSXWK6o97hax3LySaS',
                    'M1AKGJLb1xiPz4zn78HpKBPH9BpeM4M2ZeA5RysYkPV',
                    'ZdsrgUe6G1xpz5q1K8qBrw6N5MA3DG7PxDv4gbKsTM5',
                    'G1rLLQfjC1KAZrkMswcJxuKEYpgx3UWrMAd6pDAcRrKv',
                    '8qQA1bCzko8wppEhD4DHzLbSP3kffR5WGBNAs5pXW4rV',
                    'D6fUXKgfX7HduBpt3bMteGn8shPJ6owHxDzYW9DrkZYn',
                    'J1MmPi5PzSNrsBQgzYQgLcaKkzADuscS68onDJU4P78S',
                    '7VmRtqmtzZasa8z45CCZAZwcD4bXJTKCdydo3EYhVAsV',
                    'GNagVK6xYpXq3EMbRcgw6AWYSZABx11KFAKjMYjECSPq',
                    '8rtwWmhuN3MqFzUkHzLCiiynkiM42vfHq1u4hWEmU3hh',
                    '9YEiWSLBez7YdPU2ggz3cgHMZUboBWQN9NbZB9rZrtaG',
                    'CnXYYx1u6sCkcYrfEQdVXsMwT7V3vhkSHS77wooxYZ9S',
                    '9DBUdQyMsgNma19ejvn3KEGxgUGXKHiD64bejJHQPtL7',
                    '3vuakUu29sAqnrReijx5139QXxbd2b6ZRH1ggeuvC9Dw',
                    '32NmJvbgQKYShbzspRCJCDWwuhy4yq1Schinkp5A8c39',
                    'AokjvAQMRH7XPhCGzKtY8GVjewxTABLs81s7jzfGuGp5',
                    'ATe5tCuUUPrnifdw9R3CtHH32VH9vKe4ydR4E6mJ84Kz',
                    '339iNfBTthpL3dQGNTetd1b4uU4HDswPBSJwmhNkoizP',
                    'FCnudW3VZyLzbT9tNS4yfjLzm8x2icxLR1Zoj9jeLcz7',
                    '3pQmiBPCEdo5qwyVnr1AUSbKXMpWUpYho81pUb8XYJZv',
                    'BdbdEju8jLz8G9iLHj4gTyshozR1tap3NpCBbpVtwKwM',
                    'HT8rfKEhxdFwRiMgGQb5xA7RVeW4cBt8mAuDKQLFZkxn',
                    'J8uXzPTxDg1RmpT2ycHzDRXBgAW7i1udtZEm5Ynjohs1',
                    '6r8pxTYmSrBxLc1Akcf5VrfNRru6dtku25sKtNcmbhEX',
                    '7Sqg2kunkGKLeFnxcEdAVpse9rDqmbdvv3xdfwGyqpCe',
                    '5Nzzt8goFSUjrUFABfhtX27TYsWU4jgpZu9Q8hptDzuV',
                    'CGdeot2RwbQSFWPLHoFTEpRB6thhnWvV7Ya1Rsxo5KCV',
                    'xbyGCMpyid3b1HU6pxxb3wJSe4Rsqa597ozYLZFCkuN',
                    '8hbJB13TkukY8RdofLpC4qhNaqd2iRYifQ7YzuafkLYP',
                    'Eqq345p16npRXyzvD3y4kTznmHWKkKK18wWQ1e5yxwWm',
                    '5JDmSziXjm2gNJqzAFaarf2HmVa4Q3nj7jft9hTWG4Tw',
                    'FMiuLDX9dpP6zQLuiAUyGh2YPLaCZRwH6LFjyy1iGSZq',
                    'AVbjAg3t7LDme5e58vhYmBEWy5sQjgEcWCMAwmCMG9Rq',
                    '6kvmmNxYrUoGwFUzBz4ebgTqbtwW2Fpf7rYAwYMQj2no',
                    'CQbgeQu93Ut4KrxRrrrKDqprXhomYy96WRWeXwZZgg3f',
                    'Cwz3gj1brMQAfkdTaMsdqh91fyRKWobgzZ9DP3GZdTER'
                ];

                Object.assign(mintArgs, {
                    solPayment: some({
                        destination: new PublicKey(candyMachine.authority)
                    }),
                    allowList: some({
                        merkleRoot: getMerkleRoot(allowList),
                    })
                });

            } else if (group_label == 'og') {

                allowList = [
                    '4eUgGApaf7eLTVJCi3RRAdpg7UTFreMiw8NipnFMaosS',
                    'BkvgE4TandrvrYShQfd2VwKAGhTMgVhUT3eM75tZV1ir',
                    'C4yX7iBEf5XxCE297YqTshp86cQA7cobkUDAeQxVRfYy',
                    '8rPJ5YrWvb8oGUYRrV7bGUzkJa5ZkwiVNYej86xJxKmE',
                    'CbMAXitEa23My9CnCaeUGTPhR9gDTeQSiHWKCnp8qjBh',
                    '7GCdAAT9CkHvSY2dgCSDSewisST7JAbYcbGXTiMhiT77',
                    '4CbVoH3FVB9uQT5Vvomy2TWVQXgVpi3bASsUwNaXZLFe',
                    '6uUG2H4rsGjyxM5SoNoWkSycUc62kPUYgeebUrWou8s',
                    'CiEmMB92LG68Ps4p8f9uWam1mzzXiKUxjTQTPDfKY1m8',
                    '3Wo9pLiWmcc4vU7oGWjk17xphHw1coWXNqahSjAY5CAR',
                    'DL6dghMbjoSJGrDw11AkEHYJvEpjpxe1eZqm4jmKDA4L',
                    'FzwHrEmrk8pTWBTFVaBbnczqeFPLFi43Ara6wChPiAvi',
                    '4f184vo6VaVBbMo1uGhRaBKuQTKV6ffc9TY5kJnXooji',
                    'FpUTEQSgkGugKtWALTMY5SaRaimqHW6TaFohbtuiEufp',
                    '4gC6UCLuEE1n8qa8Lmw2QKifJ3BNC3LiiQ8DkiBrP67q',
                    '46vWvHYJZmhAjx75LKnXFLYF7wx612Lg7kSNN8yPt6qp',
                    '74sYknc78vXTi1BbT8xGtEgxpdV7HcnMZQEn7BB1RYn4',
                    'JCpwXQNdxppSakmMAnzCiiAgucdZFUmrC3Tg5J86nmTC',
                    'FF3fQkihxQfGnRwcyCg991uDrctQfzRB7yKpn2HAR3T3',
                    'HbDbSesTNHi7m4yhK5L8r9ydNzHawrjpy9MopkLyi3BE',
                    '3uDDP2cVUwBZFot359m8Jv464FNgq6nq76q8qrWzoppZ',
                    'FsVannbQS1Difk5QrmGCStoypxkvxMsUTN12eCtaG6FK',
                    'GuUduxukTR2N2R8Ez7SFBPq37mtiNqxE8PexQyopGs94',
                    'FDaTz3QyDhywkeYfGKyVZm5YJpogtHbZSrHhBiRGPCxE'
                ];

                Object.assign(mintArgs, {
                    solPayment: some({
                        destination: new PublicKey(candyMachine.authority)
                    }),
                    allowList: some({
                        merkleRoot: getMerkleRoot(allowList),
                    }),
                    mintLimit: some({
                        id: 1,
                        limit: 1
                    })
                });

            }
    
            // Verify the Merkle Proof by specifying which group to select.
            await route(umi, {
                candyMachine: candyMachine.publicKey,
                candyGuard: candyGuard.publicKey,
                guard: "allowList",
                group: some(group_label),
                routeArgs: {
                    path: "proof",
                    merkleRoot: getMerkleRoot(allowList),
                    merkleProof: getMerkleProof(allowList, umi.identity.publicKey)
                }
            }).sendAndConfirm(umi);
    
            mintProcess.value += 1;
    
            await transactionBuilder()
                .add(setComputeUnitLimit(umi, { units: 1_000_000 }))
                .add(setComputeUnitPrice(umi, { microLamports: 350000 }))
                .add(
                    mintV2(umi, {
                        candyMachine: candyMachine.publicKey,
                        nftMint: nftMint,
                        collectionMint: candyMachine.collectionMint,
                        collectionUpdateAuthority: candyMachine.authority,
                        candyGuard: candyGuard.publicKey,
                        tokenStandard: TokenStandard.ProgrammableNonFungible,
                        group: some(group_label),
                        mintArgs: mintArgs
                    })
                )
                .sendAndConfirm(umi, {
                    confirm: {
                        commitment: "confirmed",
                    }
                });
    
            mintProcess.value += 1;

        } else {

            mintProcess.value += 1;

            await transactionBuilder()
                .add(setComputeUnitLimit(umi, { units: 800_000 }))
                .add(setComputeUnitPrice(umi, { microLamports: 500000 }))
                .add(
                    mintV2(umi, {
                        candyMachine: candyMachine.publicKey,
                        nftMint: nftMint,
                        collectionMint: candyMachine.collectionMint,
                        collectionUpdateAuthority: candyMachine.authority,
                        candyGuard: candyGuard.publicKey,
                        tokenStandard: TokenStandard.ProgrammableNonFungible,
                        group: some(group_label),
                        mintArgs: {
                            solPayment: some({
                                destination: new PublicKey(candyMachine.authority)
                            })
                        }
                    })
                )
                .sendAndConfirm(umi, {
                    confirm: {
                        commitment: "confirmed",
                    }
                });
    
            mintProcess.value += 1;

        }

    } catch (error) {

        ElMessage({
            message: error.message,
            type: 'error',
            duration: 5 * 1000,
            showClose: true
        });

        mintProcess.value = 0;
        showMintDialog.value = false;
    }

}