
import axios from 'axios';
import { useCommonStore } from '@/store';
import { Connection, PublicKey } from '@solana/web3.js';
import { getAssociatedTokenAddress, getMint } from '@solana/spl-token';
import { getUmi } from './useCandyMachine';
import { fetchDigitalAsset } from '@metaplex-foundation/mpl-token-metadata';

export const getTokenInfo = async (token_address) => {

    const decimals_map = [
        {
            symbol: 'SOL',
            float: 2
        },
        {
            symbol: 'PYTH',
            float: 4
        },
        {
            symbol: 'JUP',
            float: 4
        },
        {
            symbol: 'USDC',
            float: 0
        },
        {
            symbol: 'Bonk',
            float: 8
        },
        {
            symbol: 'WEN',
            float: 8
        },
        {
            symbol: 'WIF',
            float: 8
        }
    ]

    const umi = getUmi();
    const info = await fetchDigitalAsset(umi, new PublicKey(token_address));

    const float = decimals_map.find(item => item.symbol === info.metadata.symbol);
    
    return {
        symbol: info.metadata.symbol,
        image: info.metadata.symbol.toLowerCase(),
        float: float.float,
        decimals: info.mint.decimals
    }

}

export const getTokenBalance = async (wallet_address, token_address) => {

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new Connection(clusterUrl, 'confirmed');

    const ata = await getAssociatedTokenAddress(
        new PublicKey(token_address),
        new PublicKey(wallet_address)
    );

    try {
        const ataAccountInfo = await connection.getTokenAccountBalance(ata);
        var balance = ataAccountInfo.value.uiAmount;
    } catch (error) {
        var balance = 0;
    }

    const info = await getTokenInfo(token_address);

    return {
        balance: balance,
        info: info
    };

}

export const getSolBalance = async (wallet_address) => {

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new Connection(clusterUrl, 'confirmed');

    const sol_balance = await connection.getBalance(new PublicKey(wallet_address));

    return {
        balance: sol_balance / (10 ** 9),
    };

}