<template>
    <!-- <div class="mask-all" v-if="ifMask">
        <p class="mask-title">For the best experience,<br>please use your computer device.</p>
    </div> -->
    <nav class="navbar navbar-expand-lg navbar-dark bg-black pt-4 pb-lg-4 pb-3" :class="{ 'fixed': isMenuOpen }">
        <div class="container">
            <a class="navbar-brand d-flex align-items-center fs-18" href="/">
                <img src="./assets/logo.png" alt="" width="42" height="42" class="d-inline-block align-text-top rounded me-3">
                <span class="navbar-brand-title d-none d-sm-block">Mimir Strategies</span>
            </a>
            <div class="navbar-nav ms-auto d-none d-lg-flex align-items-center fs-18">
                <div class="me-4">
                    <a href="javascript:void(0);" class="text-decoration-none jupiter-text" @click="popupSwap">Swap</a>
                </div>
                <!-- <div class="me-4">
                    <a href="/marketplace" class="text-light text-decoration-none">Marketplace</a>
                </div>  --> 
                <div class="dropdown">
                    <a 
                        href="javascript:void(0);" 
                        class="me-4 text-decoration-none dropdown-toggle backpack-text" 
                        id="dropdownPortfolioLink" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false" 
                        v-if="walletStatus"
                    >
                    Backpack
                    </a>
                    <ul class="dropdown-menu header-dropdown-menu" aria-labelledby="dropdownPortfolioLink">
                        <li><a class="dropdown-item header-dropdown-item" href="/backpack">Backpack Farmer</a></li>
                        <li><a class="dropdown-item header-dropdown-item" href="/backpack/mint">Mint NFT</a></li>
                    </ul>
                </div>
                <div class="me-4">
                    <a href="/leaderboard" class="text-decoration-none text-light">Leaderboard</a>
                </div>
                <div class="dropdown">
                    <a 
                        href="javascript:void(0);" 
                        class="text-light me-4 text-decoration-none dropdown-toggle" 
                        id="dropdownPortfolioLink" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false" 
                        v-if="walletStatus"
                    >
                        Portfolio
                    </a>
                    <ul class="dropdown-menu header-dropdown-menu" aria-labelledby="dropdownPortfolioLink">
                        <li><a class="dropdown-item header-dropdown-item" href="/portfolio">My Portfolio</a></li>
                        <li><a class="dropdown-item header-dropdown-item" href="/portfolio/history">History Portfolio</a></li>
                    </ul>
                </div>
                <div class="dropdown">
                    <a 
                        href="javascript:void(0);" 
                        class="text-light me-4 text-decoration-none dropdown-toggle" 
                        id="dropdownStrategyLink" 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false" 
                        v-if="walletStatus"
                    >
                    Strategy
                    </a>
                    <ul class="dropdown-menu header-dropdown-menu" aria-labelledby="dropdownStrategyLink">
                        <li><a class="dropdown-item header-dropdown-item" href="/my_strategies">My Strategy</a></li>
                        <li><a class="dropdown-item header-dropdown-item" href="/create_strategy">Create Strategy</a></li>
                    </ul>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <wallet-multi-button></wallet-multi-button>
                <label class="burger-button" @click="toggleMenu">
                    <span class="burger-line" :class="{ 'menu-open': isMenuOpen }" ></span>
                </label>
            </div>
        </div>
    </nav>
    <div class="burger-menu" :class="{ 'active': isMenuOpen }">
        <div class="container fw-bold">
            <div class="fs-18 py-3" v-if="walletStatus">
                <a href="/profile" class="text-light text-decoration-none">My Profile</a>
            </div>
            <div class="fs-18 py-3">
                <a href="/marketplace" class="text-light text-decoration-none">Marketplace</a>
            </div> 
            <div class="fs-18 py-3">
                <a href="/leaderboard" class="text-decoration-none text-light">Leaderboard</a>
            </div>
            <div class="fs-18 py-3" v-if="walletStatus">
                <div 
                    class="text-light text-decoration-none d-flex" 
                    data-bs-toggle="collapse"
                    data-bs-target=".dropdown-portfolio-link"
                    :aria-expanded="isPortfolioExpanded"
                    aria-controls="multiCollapseExample1"
                    @click="toggleCollapse('portfolio')"
                >
                    <div class="pe-2 cursor-pointer" >Portfolio</div>
                    <div class="arrow-icon cursor-pointer">&#9662;</div>
                </div>
                <div class="collapse burger-menu-collapse dropdown-portfolio-link" id="dropdownPortfolioLink">
                    <div class="burger-menu-collapse-item"><a class="text-decoration-none" href="/portfolio">My Portfolio</a></div>
                    <div class="burger-menu-collapse-item"><a class="text-decoration-none" href="/portfolio/history">History Portfolio</a></div>
                </div>
            </div>
            <div class="fs-18 py-3" v-if="walletStatus">
                <div 
                    class="text-light text-decoration-none d-flex" 
                    data-bs-toggle="collapse"
                    data-bs-target=".dropdown-strategy-link"
                    :aria-expanded="isStrategyExpanded"
                    aria-controls="multiCollapseExample1"
                    @click="toggleCollapse('strategy')"
                >
                    <div class="pe-2 cursor-pointer" >Strategy</div>
                    <div class="arrow-icon cursor-pointer">&#9662;</div>
                </div>
                <div class="collapse burger-menu-collapse dropdown-strategy-link" id="dropdownStrategyLink">
                    <div class="burger-menu-collapse-item"><a class="text-decoration-none" href="/my_strategies">My Strategy</a></div>
                    <div class="burger-menu-collapse-item"><a class="text-decoration-none" href="/create_strategy">Create Strategy</a></div>
                </div>
            </div>
            <div class="fs-18 py-3">
                <a href="javascript:void(0);" class="text-decoration-none jupiter-text" @click="popupSwap">Swap</a>
            </div>
            <div class="fs-18 py-3" v-if="walletStatus">
                <div 
                    class="backpack-text text-decoration-none d-flex" 
                    data-bs-toggle="collapse"
                    data-bs-target=".dropdown-backpack-link"
                    :aria-expanded="isPortfolioExpanded"
                    aria-controls="multiCollapseExample1"
                    @click="toggleCollapse('backpack')"
                >
                    <div class="pe-2 cursor-pointer" >Backpack</div>
                    <div class="arrow-icon cursor-pointer">&#9662;</div>
                </div>
                <div class="collapse burger-menu-collapse dropdown-backpack-link" id="dropdownPortfolioLink">
                    <div class="burger-menu-collapse-item"><a class="text-decoration-none" href="/backpack">Backpack Farmer</a></div>
                    <div class="burger-menu-collapse-item"><a class="text-decoration-none" href="/backpack/mint">Mint NFT</a></div>
                </div>
            </div>
        </div>
    </div>
    <router-view />
</template>

<script>
import { initWallet, WalletMultiButton } from 'solana-wallets-vue';
import { initWorkspace, initMetaplex, useWorkspace, loginOrSignup } from '@/composables';
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import { computed, watch, onMounted, ref } from 'vue';
import { PublicKey } from '@solana/web3.js';
import { useWalletStore, useCommonStore } from '@/store';

export default {
    name: 'App',
    components: {
        WalletMultiButton
    },
    setup() {
        const isMenuOpen = ref(false);
        const isPortfolioExpanded = ref(false);
        const isStrategyExpanded = ref(false);

        const closeBurgerMenu = () => {
            isPortfolioExpanded.value = false
            isStrategyExpanded.value = false
            const collapseElement = document.getElementsByClassName('burger-menu-collapse');
            if (collapseElement.length > 0) {
                Array.from(collapseElement).forEach(element => {
                    element.classList.remove('show');
                });
            }
        }

        const toggleCollapse = (item) => {
            if (item === 'portfolio') isPortfolioExpanded.value = !isPortfolioExpanded.value;
            else if (item === 'strategy') isStrategyExpanded.value = !isStrategyExpanded.value;
        };
        const toggleMenu = () => {
            isMenuOpen.value = !isMenuOpen.value;
            if (!isMenuOpen.value) {
                closeBurgerMenu()
            }
        };
        const closeMenu = () => {
            isMenuOpen.value = false
            closeBurgerMenu()
        }

        // 初始化錢包
        const walletOptions = {
            wallets: [
                new PhantomWalletAdapter()
            ],
            autoConnect: true
        };

        initWallet(walletOptions);
        initWorkspace();
        initMetaplex();

        // 取得錢包及錢包地址
        const walletStore = useWalletStore();
        const { wallet } = useWorkspace();
        const walletAddress = computed(() => wallet.publicKey?.value?.toString());
        const walletStatus = computed(() => wallet.connected.value);

        watch(walletAddress, async (newAddress, oldAddress) => {
            if (newAddress && newAddress !== oldAddress) {
                await loginOrSignup(newAddress);
            }
        });

        watch(walletStatus, async (newStatus, oldStatus) => {

            walletStore.connect_status = newStatus;

            if (newStatus == false && oldStatus == true) {
                window.$cookies.remove("userToken");
                window.$cookies.remove("accessToken");
            }
            
        });

        const commonStore = useCommonStore();

        // window width
        const windowWidth = computed(() => commonStore.windowWidth);

        // mask 設定（僅開放電腦使用，暫不考慮手機裝置）
        const ifMask = ref(false);

        watch([windowWidth, isMenuOpen], ([newWidth, menuOpen]) => {
            if (newWidth <= 991) {
                ifMask.value = true;
                document.body.style.overflow = menuOpen ? 'hidden' : '';
            } else {
                ifMask.value = false;
                document.body.style.overflow = '';
            }
        });

        // 初始化 Jupiter Terminal
        const popupSwap = async () => {
            closeMenu()
            const solanaNetwork = commonStore.solanaNetwork;
            const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

            const referralAccount = {
                referralAccount: new PublicKey('8QenxJXGQkpSFFAFkxVPDuW3gGhQAY7MRiYk5MXBmrvx'),
                feeBps: 10
            }

            await window.Jupiter.init({
                endpoint: clusterUrl,
                platformFeeAndAccounts: referralAccount
            });
        }

        onMounted(() => {

            window.addEventListener('resize', () => {
                commonStore.windowWidth = window.innerWidth;
            });

            // 初始化 mask 設定
            if (windowWidth.value <= 991) {
                ifMask.value = true;
                // document.body.style.overflow = 'hidden';
            }

            // 手機初始化 auto connect
            if (window.$cookies.isKey("userToken") && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {

                console.log("mobile connect to wallet");

                setTimeout(async () => {
                    const walletName = window.$cookies.get("walletName");
                    await wallet.select(walletName);
                }, 200);

            }

        });

        return {
            windowWidth,
            walletStatus,
            ifMask,
            popupSwap,
            toggleMenu,
            isMenuOpen,
            isPortfolioExpanded,
            isStrategyExpanded,
            toggleCollapse
        }

    }
}

</script>
  
<style>
@import url('./assets/css/style.css');

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}

#jupiter-terminal > div{
    z-index: 2;
}
/* #jupiter-terminal .w-screen {
    width: auto!important;
} */

@media screen and (max-width: 768px) {
    .navbar-brand img{
        width: 40px;
        height: 40px;
    }
}
</style>

<style lang="scss">
.arrow-icon {
    transition: transform 0.3s ease;
}

[aria-expanded="true"] .arrow-icon {
    transform: rotate(-90deg);
}
.cursor-pointer {
    cursor: pointer;
}
.burger {
    &-menu {
        display: none;
        position: absolute;
        top: 90px;
        left:0;
        width: 100%;
        height: 0;
        overflow-x: hidden;
        transition: height 0.3s ease;
        background-color: #000;
        padding: 0 20px;
        &.active {
            height: 100vh;
            z-index: 3;
        }
        div {
            text-align: left;
        }
        &-collapse {
            &-item{
                padding-top: 1rem;
                padding-left: 4rem;
                cursor: pointer;
                a {
                    color: rgba(255,255,255,0.6);
                    &:hover {
                        color: rgba(255,255,255,0.8);
                    }
                }
            }
        }
        .container {
            min-height: 500px;
        }
    }
    &-button {
        display: none;
    }

}
// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
    .burger {
        &-menu {
            display: block;
            position: fixed;
        }
        &-button {
            display: block;
            cursor: pointer;
            float: right;
            padding: 20px 0.5rem;
            margin-left: 0.5rem;
        }
        &-line {
            background: #fff;
            display: block;
            height: 2px;
            position: relative;
            width: 24px;
            &.menu-open {
                background: transparent;
                &:before {
                    transform: rotate(-45deg);
                    top:0;
                }
                &:after {
                    transform: rotate(45deg);
                    top:0;
                }
            }
            &:before, &:after {
                background: #fff;
                content: '';
                display: block;
                height: 100%;
                position: absolute;
                transition: all .2s ease-out;
                width: 100%;
            }
            &:before {
                top:5px;
            }
            &:after {
                top: -5px;
            }
        }
    }
}
</style>
  