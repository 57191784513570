import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/my_strategies',
    name: 'my_strategies',
    component: () => import(/* webpackChunkName: "mystrategies" */ '../views/MyStrategiesView.vue')
  },
  {
    path: '/create_strategy',
    name: 'create_strategy',
    component: () => import(/* webpackChunkName: "create_strategy" */ '../views/CreateStrategyView.vue')
  },
  {
    path: '/mint_strategy',
    name: 'mint_strategy',
    component: () => import(/* webpackChunkName: "mint_strategy" */ '../views/MintStrategyView.vue'),
    children: [
      {
        path: ':strategy_address',
        name: 'mint_strategy_address',
        component: () => import(/* webpackChunkName: "mint_strategy" */ '../views/MintStrategyView.vue'),
      },
    ], 
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue')
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: () => import(/* webpackChunkName: "marketplace" */ '../views/LeaderBoardView.vue')
  },
  {
    path: '/marketplace',
    name: 'marketplace',
    component: () => import(/* webpackChunkName: "marketplace" */ '../views/marketplace/HomeView.vue')
  },
  {
    path: '/marketplace/detail/:strategy_address',
    name: 'marketplace_detail',
    component: () => import(/* webpackChunkName: "marketplace_detail" */ '../views/marketplace/DetailView.vue')
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import(/* webpackChunkName: "portfolio" */ '../views/portfolio/PortfolioView.vue')
  },
  {
    path: '/portfolio/history',
    name: 'portfolio_history',
    component: () => import(/* webpackChunkName: "portfolio" */ '../views/portfolio/PortfolioHistoryView.vue')
  },
  {
    path: '/portfolio/:semi_custodial_wallet_address',
    name: 'portfolio_detail',
    component: () => import(/* webpackChunkName: "portfolio" */ '../views/portfolio/PortfolioDetailView.vue')
  },
  {
    path: '/backpack',
    name: 'backpack',
    component: () => import(/* webpackChunkName: "marketplace" */ '../views/BackpackView.vue')
  },
  {
    path: '/backpack/mint',
    name: 'backpack_mint',
    component: () => import(/* webpackChunkName: "marketplace" */ '../views/BackpackMintView.vue')
  },
  {
    path: '/auth/telegram',
    name: 'telegram_auth',
    component: () => import(/* webpackChunkName: "marketplace" */ '../views/auth/TelegramAuthView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
