import { Metaplex, walletAdapterIdentity } from '@metaplex-foundation/js';
import { useWallet } from 'solana-wallets-vue';
import { Connection } from "@solana/web3.js";
import { useCommonStore } from '@/store';

let metaplex = null;

export const useMetaplex = () => metaplex;

export const initMetaplex = () => {

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const connection = new Connection(clusterUrl, "confirmed");
    const wallet = useWallet();
    if (wallet) {
        metaplex = Metaplex.make(connection).use(walletAdapterIdentity(wallet));
    } else {
        metaplex = Metaplex.make(connection)
    }
}