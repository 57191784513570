import { useCommonStore } from '@/store';
import axios from 'axios';
import { dateToString } from './useCommon';

export const backtestStrategy = async (
    symbol, buy_point_settings, sell_point_settings, 
    backtest_period, sl_point, tp_point, leverage
) => {

    const start_date = dateToString(new Date(backtest_period[0]));
    const end_date = dateToString(new Date(backtest_period[1]));

    const commonStore = useCommonStore();
    const backtestApiUrl = commonStore.backtestApiUrl;

    const userToken = window.$cookies.get('userToken');

    const data = await axios({
        method: "post",
        url: `${backtestApiUrl}/backtest/strategy`,
        headers: {
            Authorization: `Bearer ${userToken}`
        },
        data: {
            symbol: symbol,
            buy_point_settings: buy_point_settings,
            sell_point_settings: sell_point_settings,
            start_date: start_date,
            end_date: end_date,
            sl_point: sl_point ? parseFloat(sl_point / 100) : null,
            tp_point: tp_point ? parseFloat(tp_point / 100) : null,
            leverage: parseInt(leverage)
        }
    }).then(response => {

        const result = response.data.Result;

        delete result.stats['Profit Factor'];
        delete result.stats['Expectancy'];
        delete result.stats['Open Trade PnL'];
        delete result.stats['Max Gross Exposure [%]'];
        delete result.stats['Total Open Trades'];
        delete result.stats['Total Closed Trades'];

        return result;

    }).catch(error => {
        if (error.response.status === 403) {
            throw new Error('Please connect your wallet and sign in');
        } else if (error.response.status === 400) {
            throw new Error(error.response.data.Status.Desc);
        }
    });

    return data;

}

export const upsertBacktestRecords = async (
    trading_pair, backtest_period, buy_point_settings, 
    sell_point_settings, backtest_stats, sl_point, 
    tp_point, leverage, trading_type
) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const start_date = dateToString(new Date(backtest_period[0]));
    const end_date = dateToString(new Date(backtest_period[1]));

    const result = await axios({
        method: "post",
        url: `${webApiUrl}/backtest/upsert`,
        headers: {
            Authorization: `Bearer ${userToken}`
        },
        data: {
            trading_pair: trading_pair,
            start_date: start_date,
            end_date: end_date,
            buy_point_settings: buy_point_settings,
            sell_point_settings: sell_point_settings,
            backtest_stats: backtest_stats,
            sl_point: sl_point,
            tp_point: tp_point,
            leverage: leverage,
            trading_type: trading_type
        }
    }).then(() => {
        return true;
    }).catch(() => {
        return false;
    });

    return result;
    
}

export const getBacktestRecords = async (page) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const data = await axios({
        method: "get",
        url: `${webApiUrl}/backtest/records`,
        headers: {
            Authorization: `Bearer ${userToken}`
        },
        params: {
            page: page
        }
    }).then(response => {
        return response.data.Result;
    }).catch((error) => {
        console.log(error);
    });

    return data;
    
}