import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// highcharts
import HighchartsVue from 'highcharts-vue';

// pinia
import { createPinia } from 'pinia';

// vue cookies
import VueCookies from 'vue-cookies';

// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap"

// element plus
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import "element-plus/theme-chalk/index.css";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { 
  faTrash, faPenToSquare, faChevronRight, 
  faChevronLeft, faGlobe, faQrcode, 
  faShield, faFile, faTriangleExclamation,
  faCircleQuestion, faEye, faBook, faPlus,
  faRightToBracket, faSort, faSortUp, faSortDown,
  faBell, faRepeat
} from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons';

/* add icons to the library */
library.add(faTrash);
library.add(faPenToSquare);
library.add(faChevronRight);
library.add(faChevronLeft);
library.add(faGlobe);
library.add(faDiscord);
library.add(faTwitter);
library.add(faQrcode);
library.add(faShield);
library.add(faFile);
library.add(faTriangleExclamation);
library.add(faCircleQuestion);
library.add(faEye);
library.add(faBook);
library.add(faPlus);
library.add(faRightToBracket);
library.add(faSort);
library.add(faSortUp);
library.add(faSortDown);
library.add(faTelegram);
library.add(faBell);
library.add(faRepeat);

// solana wallet css
import "solana-wallets-vue/styles.css";


const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app
  .use(ElementPlus)
  .use(VueCookies)
  .use(createPinia())
  .use(HighchartsVue)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
