<template>
	<div class="card bg-dark rounded p-0" style="cursor: pointer;">
		<div class="card-body p-0">
			<div style="position: relative;">
				<img 
					:src="data.image" 
					@error="data.image = require(`../assets/logo.png`)"
					class="w-100 rounded-top" 
					v-if="data.image" 
					:style="windowWidth > 768 ? 'aspect-ratio: 1;' : 'aspect-ratio: 8/5; object-fit: cover;'"
				/>
				<div class="strategy-trading-pair">
					<img :src="require(`../assets/images/coin/${data.coin_image}.png`)" class="rounded-circle main-coin" width="26" height="26" />
					<img src="../assets/images/coin/usdc.png" class="rounded-circle basic-coin" width="26" height="26" />
				</div>
				<span class="badge bg-success strategy-status-badge" v-if="data.is_deployed">Running</span>
				<span class="badge bg-warning text-dark strategy-status-badge" v-else>Pending</span>
				<span class="badge rounded-pill bg-black text-light countdown-status-badge" v-if="windowWidth <= 768">
					<div class="row justify-content-center align-items-center fs-14 mx-1" v-if="data.countdownCode === 2">
						<p class="text-light mb-0 w-auto p-0 countdown-unit">starts in:&nbsp;</p>
						<p class="text-light mb-0 w-auto p-0 countdown-unit">00D&nbsp;</p>
						<p class="text-light mb-0 w-auto p-0 countdown-unit">00H&nbsp;</p>
						<p class="text-light mb-0 w-auto p-0 countdown-unit">00M&nbsp;</p>
					</div>
					<p 
						class="fs-14 fw-bold mb-0 w-auto p-0 countdown-unit d-flex align-items-center px-1" 
						v-if="data.countdownCode === 1"
					>
						<el-icon class="me-1" color="rgb(0 209 129)"><Clock /></el-icon>
						Live
					</p>
				</span>
			</div>
			<p class="text-light mt-3 mb-1 px-3 strategy-name-title">{{ data.name }}</p>
			<div v-if="launchpad">
				<div class="row justify-content-center align-items-center" :class="windowWidth > 768 ? 'py-3' : 'pt-2 pb-3'">
					<div class="col-5 px-3">
						<p 
							class="text-light mb-1" 
							:class="windowWidth <= 768 ? 'fs-14 fw-500' : ''"
						>
							ROI
						</p>
						<p 
							class="fw-bold mb-0" 
							:class="returnTextClass(data?.total_return), windowWidth <= 768 ? 'fs-16' : ''" 
							style="white-space: nowrap; overflow-x: auto"
						>
							{{ data?.total_return?.toFixed(2) }} %
						</p>
					</div>
					<el-divider class="p-0" direction="vertical" style="height: 25px;"></el-divider>
					<div class="col-5 px-3">
						<p 
							class="text-light mb-1" 
							:class="windowWidth <= 768 ? 'fs-14 fw-500' : ''"
						>
							Sharpe
						</p>
						<p 
							class="fw-bold mb-0" 
							:class="returnTextClass(data?.total_return), windowWidth <= 768 ? 'fs-16' : ''" 
							style="white-space: nowrap; overflow-x: auto"
						>
							{{ data.sharpe_ratio?.toFixed(2) }}
						</p>
					</div>
				</div>
				<div 
					class="row justify-content-center align-items-center bg-black rounded mx-3 mb-3 mt-2 py-2 fs-16 fw-500" 
					v-if="windowWidth > 768"
				>
					<p class="mb-0 w-auto p-0 countdown-unit me-2 d-flex align-items-center" style="color: rgb(0 209 129); font-weight: bold" v-if="data.countdownCode === 1">
						<el-icon class="me-1" color="rgb(0 209 129)"><Clock /></el-icon>
						Live
					</p>
					<p class="text-light mb-0 w-auto p-0 countdown-unit" v-if="data.countdownCode === 2">starts in:&nbsp;</p>
					<p class="text-light mb-0 w-auto p-0 countdown-unit" v-if="data.countdownCode === 2">{{ data.countdown?.day }}D&nbsp;</p>
					<p class="text-light mb-0 w-auto p-0 countdown-unit" v-if="data.countdownCode === 2">{{ data.countdown?.hr }}H&nbsp;</p>
					<p class="text-light mb-0 w-auto p-0 countdown-unit" v-if="data.countdownCode === 2">{{ data.countdown?.min }}M&nbsp;</p>
				</div>
			</div>
			<div class="row px-3 pt-3 pb-4" v-else>
				<div class="col-6 border-end">
					<p class="text-light mb-1">Floor Price</p>
					<p class="fw-bold mb-0 text-light d-flex justify-content-center align-items-center">
						{{ data?.floor_price?.toFixed(2) ?? '--' }}
						<img src="../assets/images/coin/sol_market.png" class="ms-2" width="16" height="16" />
					</p>
				</div>
				<div class="col-6">
					<p class="text-light mb-1">Total ROI&nbsp;(%)</p>
					<p 
						class="fw-bold mb-0"
						:class="returnTextClass(data.total_roi)"
						style="white-space: nowrap; overflow-x: auto"
					>
						{{ data.total_roi?.toFixed(2) ?? '--' }}&nbsp;
						<span class="fs-14">%</span>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
defineProps({
	data: Object,
	launchpad: Boolean,
});

import { computed } from 'vue';
import { returnTextClass,} from '@/composables';
import { useCommonStore } from '@/store';
const commonStore = useCommonStore();
const windowWidth = computed(() => commonStore.windowWidth);

</script>

<style scoped>
.countdown-status-badge {
	position: absolute;
    bottom: 10px;
    left: 10px;
}

.strategy-status-badge {
    position: absolute;
    top: 10px;
    right: 10px;
}

.strategy-trading-pair {
    position: absolute;
    top: 10px;
    left: 10px;
}

.strategy-trading-pair .main-coin {
    position: absolute;
    z-index: 1;
}

.strategy-trading-pair .basic-coin {
    position: absolute;
    left: 18px;
}

/* strategy-name-title */
.strategy-name-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	font-size: 1.125rem;
}

@media screen and (max-width: 768px) {
	.strategy-name-title {
		font-weight: bold;
		margin-top: 1rem !important;
	}
}
</style>