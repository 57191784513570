import { useStrategyStore } from "@/store";
import axios from "axios";

export const getLastestPrice = async (token_address) => {

    const url = 'https://price.jup.ag/v6/price';

    const payload = {
        ids: token_address,
    };

    const headers = {
        'accpet': 'application/json'
    }

    const data = await axios({
        method: 'get',
        url: url,
        headers: headers,
        params: payload
    }).then(response => {
        return response.data.data;
    }).catch(error => {
        console.log(error);
    });

    return {
        [token_address]: data[token_address].price
    };

}

export const getMultipleLastestPrice = async (addresses) => {

    const list_address = addresses.join(',');
    
    const url = 'https://price.jup.ag/v6/price';

    const payload = {
        ids: list_address,
    };

    const headers = {
        'accpet': 'application/json'
    }

    const data = await axios({
        method: 'get',
        url: url,
        headers: headers,
        params: payload
    }).then(response => {
        return response.data.data;
    }).catch(error => {
        console.log(error);
    });

    const latest_multiple_price = {};
    addresses.forEach((address, index) => {
        const price = data[address].price;
        latest_multiple_price[address] = price;
    });

    const strategyStore = useStrategyStore();
    Object.assign(strategyStore.latest_multiple_price, latest_multiple_price);

};