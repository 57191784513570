import axios from 'axios';
import { useCommonStore } from '@/store';

export const getUserBasic = async () => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const data = await axios({
        url: webApiUrl + '/user/basic',
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userToken
        }
    }).then(response => {
        const result = response.data.Result;
        return result;
    }).catch(error => {
        console.log(error);
        return null;
    });

    return data;
}

export const getLeaderboard = async () => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const data = await axios({
        url: webApiUrl + '/user/leaderboard',
        method: 'get'
    }).then(response => {
        const result = response.data.Result;
        return result;
    }).catch(error => {
        return null;
    });

    return data;

}