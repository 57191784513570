import CryptoJS from 'crypto-js';
import { useCommonStore } from '@/store';
import axios from 'axios';
import dotenv from 'dotenv';

// 可讀取 .env 檔案
dotenv.config();

export const uploadStrategyImage = async (base64_image, collection_address) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const pwd = `MimirLab_${collection_address}_${process.env.VUE_APP_UPLOAD_IMAGE_KEY}`;
    const apiKey = CryptoJS.SHA256(pwd).toString(CryptoJS.enc.Hex);

    const image_url = await axios({
        method: 'post',
        url: webApiUrl + '/storage/upload/strategy_image',
        headers: {
            'Authorization': 'Bearer ' + userToken,
            'x-api-key': apiKey,
        },
        data: {
            base64_image: base64_image,
            collection_address: collection_address,
        }
    }).then((response) => {
        return response.data.Result;
    }).catch((error) => {
        console.log(error.response.data);
        return null;
    });

    return image_url;

}

export const uploadStrategyMetadata = async (metadata, collection_address) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const pwd = `MimirLab_${collection_address}_${process.env.VUE_APP_UPLOAD_IMAGE_KEY}`;
    const apiKey = CryptoJS.SHA256(pwd).toString(CryptoJS.enc.Hex);


    const result = await axios({
        method: 'post',
        url: webApiUrl + '/storage/upload/metadata',
        data: {
            collection_address: collection_address,
            metadata: metadata
        },
        headers: {
            'Authorization': 'Bearer ' + userToken,
            'x-api-key': apiKey,
        }
    }).then(() => {
        return true;
    }).catch((error) => {
        console.log(error.response.data);
        return false;
    });

    return result;

}