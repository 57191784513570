import { useBackpackStore, useCommonStore } from '@/store';
import { useMetaplex, getUserBasic } from '@/composables';
import axios from 'axios';
import { ElLoading, ElMessage } from 'element-plus';
import { useWallet } from 'solana-wallets-vue';

export const getBackpackMarkets = async () => {
    
    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const data = await axios({
        method: "get",
        url: `${webApiUrl}/backpack/markets`,
    }).then((response) => {
        return response.data.Result;
    }).catch((error) => {
        ElMessage.error(error.response.data.Status.Desc);
    });

    return data;
}

export const checkBackpackBalance = async (api_key, api_secret) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const balance = await axios({
        method: "post",
        url: `${webApiUrl}/backpack/balance`,
        headers: {
            Authorization: `Bearer ${userToken}`
        },
        data: {
            api_key: api_key,
            api_secret: api_secret
        }
    }).then((response) => {

        const balances = response.data.Result;
        const backpackStore = useBackpackStore();
        backpackStore.trade_records.unshift({
            type: 'balance',
            balances: {
                Time: {
                    available: new Date().toLocaleString(),
                },
                ...balances
            }
        });

        if (backpackStore.trade_records.length > 20) {
            backpackStore.trade_records.pop();
        }

        return balances;

    }).catch((error) => {
        ElMessage.error(error.response.data.Status.Desc);
    });

    return balance;

};

const trade = async (api_key, api_secret, symbol, min_quantity, type = 'buy') => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    const tx = await axios({
        method: "post",
        url: `${webApiUrl}/backpack/${type}`,
        headers: {
            Authorization: `Bearer ${userToken}`
        },
        data: {
            api_key: api_key,
            api_secret: api_secret,
            symbol: symbol,
            min_quantity: min_quantity
        }
    }).then((response) => {

        const data = response.data.Result;

        const backpackStore = useBackpackStore();
        backpackStore.trade_records.unshift({
            type: 'trade',
            ...data
        });

        if (backpackStore.trade_records.length > 20) {
            backpackStore.trade_records.pop();
        }

        return data;


    }).catch((error) => {
        ElMessage.error(error.response.data.Status.Desc);
        if (error.response.status == 403) {
            return {
                status: 'Closed',
            };
        } else {
            return {
                status: 'Failed',
            };
        }
    });

    return tx;

}

const checkFarmingPermission = async () => {

    ElLoading.service({
        lock: true,
        text: 'Checking farming permission...',
        background: 'rgba(0, 0, 0, 0.7)'
    });

    const userInfo = await getUserBasic();

    if (!userInfo) {

        ElLoading.service().close();
        ElMessage.error('You are not allowed to farm. Please connect your wallet and login first.');
        return null;

    } else if (userInfo?.discord_id === "") {

        ElLoading.service().close();
        ElMessage.error('You are not allowed to farm. Please connect your discord account first.');
        return null;

    }

    const MIMIR_GOD_COLLECTION_ADDRESS = '2LsU4R3MiGc7VChDerXQjAYf5TZj83LdbXavqgvnBQk5';
    const MIMIR_SMART_BACKPACK_ADDRESS = 'G7iYx6cxE4jund8T25qqxGmNd2cHM3kNFgqF6ipyRhYz';

    const wallet = useWallet();
    const metaplex = useMetaplex();

    const userNfts = await metaplex.nfts().findAllByOwner({
        owner: wallet.publicKey.value,
    })

    const ifExist = userNfts.find(nft => 
        nft.collection?.address?.toBase58() === MIMIR_GOD_COLLECTION_ADDRESS ||
        nft.collection?.address?.toBase58() === MIMIR_SMART_BACKPACK_ADDRESS
    );

    await new Promise(resolve => setTimeout(resolve, 1000));
    ElLoading.service().close();

    return ifExist;

}

export const backpackFarming = async (api_key, api_secret, symbol, min_quantity, frequency) => {

    const permission = await checkFarmingPermission();
    if (permission === false) {
        ElMessage.error('You are not allowed to farm. Please own the mimir smart backpack first.');
        return;
    } else if (permission === null) {
        return;
    }

    const backpackStore = useBackpackStore();

    const balance = await checkBackpackBalance(api_key, api_secret);
    if (!balance) {
        return;
    }

    const baseSymbol = symbol.split('_')[0];

    let isOpened = false;
    if (balance[baseSymbol]?.available >= 1) {
        isOpened = true;
    }

    backpackStore.is_farming = true;

    while (true) {

        console.log(backpackStore.is_farming);

        if (backpackStore.is_farming === false) {
            break;
        }

        try {

            if (!isOpened) {

                console.log('buy position');

                const buy_tx = await trade(api_key, api_secret, symbol, min_quantity, 'buy');
                console.log('buy_tx', buy_tx);

                if (buy_tx?.status === 'Filled') {
                    isOpened = true;
                } else if (buy_tx?.status === 'Closed') {
                    backpackStore.is_farming = false;
                    break;
                }

                await new Promise(resolve => setTimeout(resolve, frequency));
                continue;

            } else if (isOpened) {

                console.log('sell position');

                const sell_tx = await trade(api_key, api_secret, symbol, min_quantity, 'sell');
                console.log('sell_tx', sell_tx);

                if (sell_tx?.status === 'Filled') {
                    isOpened = false;
                } else if (sell_tx?.status === 'Closed') {
                    backpackStore.is_farming = false;
                    break;
                }

                await new Promise(resolve => setTimeout(resolve, frequency));
                continue;

            }

        } catch (e) {
            console.log(e);
            await new Promise(resolve => setTimeout(resolve, frequency));
            continue;
        }

    }

};