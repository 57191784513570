<template>

    <div class="container mt-4" style="position: relative;">
        <img 
            src="../assets/images/banner/power_of_runes.png" 
            class="w-100 rounded home-banner"
            @click="goToPage('/backpack/mint')"
        >
        <div 
            id="mint-link-div"
            @click="goToPage('/backpack/mint')"
        >
            <a href="javascript:void(0)" class="text-light text-decoration-none" id="mint-link">
                Get Smart Backpack
                <font-awesome-icon :icon="['fas', 'right-to-bracket']" />
            </a>
        </div>
    </div>
    <div class="container mt-2 mt-sm-4 mt-lg-5">
        <div class="d-flex justify-content-between align-items-center mb-lg-4 mb-3">
            <h4 class="text-light text-start mb-0" :class="windowWidth <= 768 ? 'fs-18 fw-bold' : ''">Markets</h4>
            <a class="text-light text-end text-decoration-none mb-0 fs-16 fw-bold" href="/marketplace">See more ></a>
        </div>
        <el-table
            :data="marketplaceStrategies"
            style="width: 100%;"
            @cell-click="(row, column, cell, event) => {
                if (column.property === 'name') {
                    redirect(`/marketplace/detail/${row.candy_machine_address}`)
                }
            }"
            :row-key="row => row.candy_machine_address"
            :row-style="windowWidth > 768 ? 'font-size: 1rem;' : 'font-size: 14px;'"
            header-row-style="font-size: 14px;"
            header-cell-class-name="marketplace-table-header"
        >
            <el-table-column
                prop="name"
                label="Name"
                min-width="180"
                fixed
                align="left"
            >
                <template #default="scope">
                    <div 
                        class="d-flex align-items-center"
                        :class="windowWidth > 768 ? 'py-2' : 'py-1'"
                        style="cursor: pointer"
                    >
                        <img 
                            :src="scope.row.image" 
                            class="rounded"
                            :class="windowWidth > 768 ? 'me-4' : 'me-3'" 
                            width="35" 
                            height="35"
                            @error="scope.row.image = require(`../assets/images/coin/${scope.row.trading_pair.split('/')[0].toLowerCase()}.png`)"
                        />
                        <div>
                            <div class="text-light fw-bolder strategy-name">{{ scope.row.name }}</div>
                            <div class="text-light" :class="windowWidth > 768 ? 'fs-14' : 'fs-12'">{{ scope.row.trading_pair }}</div>
                        </div>  
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="floor_price"
                label="Floor Price"
                width="100"
                min-width="100"
                align="center"
                v-if="windowWidth > 768"
            >
                <template #default="scope">
                    <p class="fw-bold mb-0 text-light d-flex justify-content-center align-items-center">
						{{ scope.row?.floor_price?.toFixed(2) ?? '--' }}
						<img 
                            src="../assets/images/coin/sol_market.png" 
                            class="ms-2" 
                            width="16" 
                            height="16"
                        />
					</p>
                </template>
            </el-table-column>
            <el-table-column
                prop="total_roi"
                label="IRR (%)"
                :min-width="windowWidth > 768 ? 140 : 110"
                align="right"
            >
                <template #default="scope">
                    <p 
						class="fw-bold mb-0"
						:class="returnTextClass(scope.row.total_roi)"
						style="white-space: nowrap; overflow-x: auto"
					>
						{{ scope.row.annualized_return?.toLocaleString() ?? '--' }}&nbsp;
						<span class="fs-14">%</span>
					</p>
                </template>
            </el-table-column>
            <el-table-column
                prop="trading_volume"
                label="Trading Volumes"
                min-width="140"
                align="right"
            >
                <template #default="scope">
                    <p 
						class="fw-bold mb-0"
						style="white-space: nowrap;"
					>
						$&nbsp;{{ scope.row.trading_volume?.toLocaleString() ?? '--'}}
					</p>
                </template>
            </el-table-column>
            <el-table-column
                prop="run_time"
                label="Run Time"
                min-width="120"
                align="center"
            >
                <template #default="scope">
                    <p 
						class="fw-bold mb-0"
						style="white-space: nowrap;"
					>
						{{ scope.row.run_time ?? '--'}}
					</p>
                </template>
            </el-table-column>
            <el-table-column
                prop="history_cum_pnl"
                label="Cum. PnL"
                width="150"
                min-width="150"
                align="right"
            >
                <template #default="scope">
                    <highcharts :options="generateChartOptions(scope.row.history_cum_pnl, scope.row.total_roi)"></highcharts>
                </template>
            </el-table-column>
        </el-table>
    </div>

    <div class="container mt-lg-5 mt-4">
        <div class="d-flex justify-content-between align-items-end">
            <h4 class="text-light text-start mb-0" :class="windowWidth <= 768 ? 'fs-18 fw-bold' : ''">Launchpad</h4>
        </div>
        <div class="row mt-4" v-if="launchpadStrategies.length > 0">
            <div class="col-12 col-xl-3 col-lg-4 mb-4"
                v-for="(item, index) in launchpadStrategies" 
                :key="index" 
                @click="redirect(`/mint_strategy/${item.candy_machine_address}`)"
            >
                <StrategyCard
                    :data="item" 
                    :launchpad="true"
                />
            </div>
        </div>
        <div class="row mt-4" v-else>
            <div class="col-12">
                <p class="text-light text-center fs-20 fw-bold">No strategies minting now.</p>
            </div>
        </div>
    </div>
</template>
  
<script>
import { reactive, onMounted, ref, computed } from 'vue';
import { 
    getLaunchpadStrategies, 
    getMarketplaceStrategies,
    returnTextClass,
    goToPage,
    timer
} from '@/composables';
import { ElMessage } from 'element-plus';
import StrategyCard from '@/components/StrategyCard.vue';
import _ from 'lodash';
import { useCommonStore } from '@/store';

export default {
    name: 'HomeView',
    components: {
        StrategyCard
    },
    setup() {

        const commonStore = useCommonStore();
        const windowWidth = computed(() => commonStore.windowWidth);

        const chartOptions = reactive({
            credits: {
                enabled: false, // 隱藏highcharts.com
            },
            chart: {
                backgroundColor: 'transparent',
                spacing: [0, 0, 0, 0],
                margin: [0, 0, 0, 0],
                height: 100
            },
            title: {
                text: null
            },
            xAxis: {
                title: {
                    text: null, // 隱藏Y軸標題
                },
                labels: {
                    enabled: false, // 隱藏X軸標籤
                },
                tickLength: 0, // 隱藏X軸刻度
                gridLineWidth: 0, // 隱藏Y軸gridlines
            },
            yAxis: {
                title: {
                    text: null, // 隱藏Y軸標題
                },
                labels: {
                    enabled: false, // 隱藏Y軸標籤
                },
                tickLength: 0, // 隱藏Y軸刻度
                gridLineWidth: 0, // 隱藏Y軸gridlines
            },
            tooltip: {
                enabled: false, // 隱藏 tooltips
            },
            legend: {
                enabled: false, // 隱藏圖例（legends）
            },
            series: [{
                type: 'areaspline',
                name: 'Returns',
                data: [0, 5.5, 7.8, 3.4, 9.6, 12.9, 14, 16.8, 13.6],
                color: '#32de84',
                fillOpacity: 0.3,
                marker: {
                    enabled: false, // 隱藏點
                },
            }]
        });
        const detectChartColor = (roi) => {
            if (roi > 0) {
                return '#32de84';
            } else {
                return '#ff4d4f';
            }
        };
        const generateChartOptions = (data, roi) => ({
            credits: {
                enabled: false, // 隱藏highcharts.com
            },
            chart: {
                backgroundColor: 'transparent',
                spacing: [0, 0, 0, 0],
                margin: [0, 0, 0, 0],
                height: 30
            },
            title: {
                text: null
            },
            xAxis: {
                title: {
                    text: null, // 隱藏Y軸標題
                },
                labels: {
                    enabled: false, // 隱藏X軸標籤
                },
                tickLength: 0, // 隱藏X軸刻度
                gridLineWidth: 0, // 隱藏Y軸gridlines
            },
            yAxis: {
                title: {
                    text: null, // 隱藏Y軸標題
                },
                labels: {
                    enabled: false, // 隱藏Y軸標籤
                },
                tickLength: 0, // 隱藏Y軸刻度
                gridLineWidth: 0, // 隱藏Y軸gridlines
            },
            tooltip: {
                enabled: false, // 隱藏 tooltips
            },
            legend: {
                enabled: false, // 隱藏圖例（legends）
            },
            plotOptions: {
                series: {
                   states: { // hide marker on hover
                        hover: {
                            enabled: false
                        }
                   },
                }
            },
            series: [{
                type: 'line',
                data: data.map(item => item.cum_pnl),
                color: detectChartColor(roi),
                fillOpacity: 0,
                marker: {
                    enabled: false, // 隱藏點
                },
            }]
        });

        const launchpadStrategies = ref([]);

        const countdown = (strategy) => {

            const now = new Date();
            const start = new Date(strategy.mint_date);

            if (now >= start) {

                strategy.countdownCode = 1;

            } else if (now < start) {

                strategy.countdownCode = 2;
                const remainingTime = start - now;

                // Calculate the days, hours, minutes, and seconds
                var day = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
                var hr = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var min = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
                var sec = Math.floor((remainingTime % (1000 * 60)) / 1000);

                day = day > 9 ? day : '0' + day;
                hr = hr > 9 ? hr : '0' + hr;
                min = min > 9 ? min : '0' + min;
                sec = sec > 9 ? sec : '0' + sec;

            }

            strategy.countdown = {
                day: day,
                hr: hr,
                min: min,
                sec: sec
            }

            setTimeout(() => {
                countdown(strategy);
            }, 1000);

        }

        const redirect = (href) => {
            window.location.href = href;
        }

        const marketplaceStrategies = ref([]);

        onMounted(async () => {

            launchpadStrategies.value = await getLaunchpadStrategies();

            // 計算倒數時間
            launchpadStrategies.value.forEach(strategy => {
                countdown(strategy);
                strategy.coin_image = strategy.trading_pair.split('/')[0].toLowerCase();
            });

            marketplaceStrategies.value = await getMarketplaceStrategies();
            marketplaceStrategies.value = _.orderBy(marketplaceStrategies.value, ['annualized_return'], ['desc']).slice(0, 5);

            marketplaceStrategies.value.forEach(strategy => {
                strategy.coin_image = strategy.trading_pair.split('/')[0].toLowerCase();
                strategy.start_time = strategy.mint_date;
                delete strategy.mint_date;
                timer(strategy);
            });

        })

        return {
            windowWidth,
            chartOptions,
            launchpadStrategies,
            marketplaceStrategies,
            ElMessage,
            redirect,
            returnTextClass,
            goToPage,
            generateChartOptions
        }

    }
}
</script>

<style lang="scss" scoped>
@import url('../assets/css/home.css');
</style>
  