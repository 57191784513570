import axios from 'axios';
import { useCommonStore } from '@/store';
import { getRawTransaction, executeTransactions, connectWallet, executeTransaction, useWorkspace, useMetaplex } from '@/composables';
import { ElLoading, ElMessage } from 'element-plus';
import { Keypair, PublicKey, VersionedTransaction, sendAndConfirmRawTransaction } from '@solana/web3.js';

require('dotenv').config();

const getMarketplaceAuthority = () => {

    const privateKeyUint8Array = new Uint8Array(JSON.parse(process.env.VUE_APP_MARKETPLACE_PRIVATE_KEY));
    const wallet = Keypair.fromSecretKey(privateKeyUint8Array);

    return wallet;

};

export const getMarketplaceStrategies = async () => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const data = await axios({
        url: webApiUrl + '/marketplace/get_all_strategies',
        method: 'get',
    }).then(response => {
        return response.data.Result;
    }).catch(error => {
        console.log(error);
    });

    return data;

};

export const getMarketplaceStrategyDetail = async (candy_machine_address) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const data = await axios({
        url: webApiUrl + '/marketplace/get_strategy_detail',
        method: 'get',
        params: {
            candy_machine_address: candy_machine_address
        }
    }).then(response => {
        return response.data.Result;
    }).catch(error => {
        console.log(error);
    });

    return data;

}

export const getMarketplaceActiveListings = async (collection_address) => {

    const commonStore = useCommonStore();
    const network = commonStore.solanaNetwork;

    const marketplaceAddress = process.env.VUE_APP_MARKETPLACE_ADDRESS;
    const webApiUrl = process.env.VUE_APP_SHYFT_API_URL_V2;

    const data = await axios({
        method: 'get',
        url: webApiUrl + '/marketplace/active_listings',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.VUE_APP_SHYFT_API_KEY,
        },
        params: {
            network: network,
            marketplace_address: marketplaceAddress,
            collection_address: collection_address,
            sort_by: 'price',
            sort_order: 'asc',
            page: 1,
            size: 50
        }
    }).then(response => {
        return response.data.result;
    }).catch(error => {
        console.log(error);
    });

    return data

}

export const getMarketplaceActiveListingsBySeller = async () => {

    const wallet = connectWallet();
    const walletAddress = wallet.publicKey.toBase58();

    const commonStore = useCommonStore();
    const network = commonStore.solanaNetwork;

    const marketplaceAddress = process.env.VUE_APP_MARKETPLACE_ADDRESS;
    const webApiUrl = process.env.VUE_APP_SHYFT_API_URL_V2;

    const data = await axios({
        method: 'get',
        url: webApiUrl + '/marketplace/active_listings',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.VUE_APP_SHYFT_API_KEY,
        },
        params: {
            network: network,
            marketplace_address: marketplaceAddress,
            seller_address: walletAddress,
            sort_by: 'price',
            sort_order: 'asc',
            page: 1,
            size: 50
        }
    }).then(response => {
        return response.data.result;
    }).catch(error => {
        console.log(error);
    });

    return data

}

export const getMarketplaceMyItems = async (collection_address) => {

    const wallet = connectWallet();
    const walletAddress = wallet.publicKey.toBase58();

    const commonStore = useCommonStore();
    const network = commonStore.solanaNetwork;

    const webApiUrl = process.env.VUE_APP_SHYFT_API_URL;

    const data = await axios({
        method: 'get',
        url: webApiUrl + '/nft/search',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.VUE_APP_SHYFT_API_KEY,
        },
        params: {
            network: network,
            wallet: walletAddress,
            collection: collection_address,
            page: 1,
            size: 50
        }
    }).then(response => {
        return response.data.result.nfts;
    }).catch(error => {
        console.log(error);
    });

    console.log(data);

    return data;

}

export const updateFloorPrice = async (candy_machine_address, floor_price) => {

    const commonStore = useCommonStore();
    const webApiUrl = commonStore.webApiUrl;

    const userToken = window.$cookies.get('userToken');

    await axios({
        method: 'post',
        url: webApiUrl + '/marketplace/update/floor_price',
        headers: {
            'Authorization': 'Bearer ' + userToken,
        },
        data: {
            candy_machine_address: candy_machine_address,
            floor_price: floor_price
        }
    });

}

export const marketplaceListNft = async (nft_address, price) => {

    const wallet = connectWallet();
    const walletAddress = wallet.publicKey.toBase58();

    const commonStore = useCommonStore();
    const network = commonStore.solanaNetwork;

    const marketplaceAddress = process.env.VUE_APP_MARKETPLACE_ADDRESS;
    const webApiUrl = process.env.VUE_APP_SHYFT_API_URL;

    const result = await axios({
        method: 'post',
        url: webApiUrl + '/marketplace/list',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.VUE_APP_SHYFT_API_KEY,
        },
        data: {
            network: network,
            seller_wallet: walletAddress,
            marketplace_address: marketplaceAddress,
            nft_address: nft_address,
            price: price
        }
    }).then(response => {
        return response.data.result;
    }).catch(error => {
        console.log(error);
    });

    const recoveredTransaction = getRawTransaction(result.encoded_transaction);

    try {

        const txids = await executeTransactions(
            [recoveredTransaction],
            {
                confirmOptions: {
                    commitment: "confirmed",
                    skipPreflight: false
                }
            }
        );

        console.log(txids);
        return result.list_state;

        // window.location.reload();

    } catch (error) {
        console.log(error);
        ElMessage({
            type: 'error',
            message: error.message,
            duration: 1500,
            showClose: true
        });
    }

}

export const marketplaceUnlistNft = async (list_state) => {

    const wallet = connectWallet();
    const walletAddress = wallet.publicKey.toBase58();

    const commonStore = useCommonStore();
    const network = commonStore.solanaNetwork;

    const marketplaceAddress = process.env.VUE_APP_MARKETPLACE_ADDRESS;
    const webApiUrl = process.env.VUE_APP_SHYFT_API_URL;

    const result = await axios({
        method: 'post',
        url: webApiUrl + '/marketplace/unlist',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.VUE_APP_SHYFT_API_KEY,
        },
        data: {
            network: network,
            seller_wallet: walletAddress,
            marketplace_address: marketplaceAddress,
            list_state: list_state
        }
    }).then(response => {
        return response.data.result;
    }).catch(error => {
        console.log(error);
    });

    console.log(result);

    const recoveredTransaction = getRawTransaction(result.encoded_transaction);

    try {

        const txids = await executeTransactions(
            [recoveredTransaction],
            {
                confirmOptions: {
                    commitment: "confirmed",
                    skipPreflight: false,
                }
            }
        );

        console.log(txids);

        // window.location.reload();

    } catch (error) {
        console.log(error);
        ElMessage({
            type: 'error',
            message: error.message,
            duration: 1500,
            showClose: true
        });
    }

};

export const marketplaceBuyNft = async (nft_address, seller_address, price, activeItems) => {

    ElLoading.service({
        lock: true,
        text: 'Buying NFT...',
        background: 'rgba(0, 0, 0, 1)'
    });

    const wallet = connectWallet();
    const buyerWallet = wallet.publicKey.toBase58();

    const marketplaceAddress = process.env.VUE_APP_MARKETPLACE_ADDRESS;

    const commonStore = useCommonStore();
    const network = commonStore.solanaNetwork;

    const webApiUrl = process.env.VUE_APP_SHYFT_API_URL;

    const result = await axios({
        method: 'post',
        url: webApiUrl + '/marketplace/buy',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.VUE_APP_SHYFT_API_KEY,
        },
        data: {
            network: network,
            buyer_wallet: buyerWallet,
            marketplace_address: marketplaceAddress,
            nft_address: nft_address,
            seller_address: seller_address,
            price: price
        }
    }).then(response => {
        return response.data.result;
    }).catch(error => {
        console.log(error);
    });

    console.log(result);

    try {

        const recoveredTransaction = getRawTransaction(result.encoded_transaction);
        const marketplaceAuthority = getMarketplaceAuthority();

        recoveredTransaction.sign([marketplaceAuthority]);

        const signTx = await wallet.signTransaction(recoveredTransaction);

        const { connection } = useWorkspace();

        const txid = await sendAndConfirmRawTransaction(
            connection,
            Buffer.from(signTx.serialize()),
            {
                skipPreflight: true,
                commitment: 'confirmed',
            },
        );

        console.log(txid);
        activeItems.value = activeItems.value.filter((activeItem) => activeItem.nft_address != nft_address);

        // window.location.reload();

    } catch (error) {
        console.log(error);
        ElMessage({
            type: 'error',
            message: error.message,
            duration: 1500,
            showClose: true
        });
    }

    ElLoading.service().close();

};