import { useAnchorWallet } from "solana-wallets-vue";
import { walletAdapterIdentity } from "@metaplex-foundation/umi-signer-wallet-adapters";
import { TokenStandard, mplTokenMetadata } from "@metaplex-foundation/mpl-token-metadata";
import { mplCandyMachine, fetchCandyMachine, mintV2, fetchCandyGuard, getMerkleRoot, getMerkleProof, route } from "@metaplex-foundation/mpl-candy-machine";
import { useCommonStore } from '@/store';
import { PublicKey } from "@solana/web3.js";
import { createUmi } from "@metaplex-foundation/umi-bundle-defaults";
import { generateSigner, transactionBuilder } from "@metaplex-foundation/umi";
import { setComputeUnitLimit, setComputeUnitPrice } from "@metaplex-foundation/mpl-toolbox";
import { some } from "@metaplex-foundation/umi";
import { ElLoading, ElMessage } from "element-plus";

const connectWallet = () => {

    const wallet = useAnchorWallet();
    return wallet.value;

}

const getUmi = () => {

    const wallet = connectWallet();

    const commonStore = useCommonStore();
    const solanaNetwork = commonStore.solanaNetwork;
    const clusterUrl = commonStore[solanaNetwork + 'RpcEndpoint'];

    const umi = createUmi(clusterUrl, "confirmed")
        .use(walletAdapterIdentity(wallet))
        .use(mplTokenMetadata())
        .use(mplCandyMachine());

    return umi;

}

export const mintNft = async (candyMachineAddress, mintFundWallet) => {

    ElLoading.service({
        lock: true,
        text: 'Minting NFT, please do not close the window.',
        background: 'rgba(0, 0, 0, 1)'
    });

    try {

        const umi = getUmi();

        const candyMachine = await fetchCandyMachine(umi, new PublicKey(candyMachineAddress));
        console.log(candyMachine);

        const candyGuard = await fetchCandyGuard(umi, candyMachine.mintAuthority);
        console.log(candyGuard);

        const nftMint = generateSigner(umi);

        await transactionBuilder()
            .add(setComputeUnitLimit(umi, { units: 800_000 }))
            .add(setComputeUnitPrice(umi, { microLamports: 500000 }))
            .add(
                mintV2(umi, {
                    candyMachine: candyMachine.publicKey,
                    nftMint: nftMint,
                    collectionMint: candyMachine.collectionMint,
                    collectionUpdateAuthority: candyMachine.authority,
                    candyGuard: candyGuard.publicKey,
                    tokenStandard: TokenStandard.ProgrammableNonFungible,
                    mintArgs: {
                        solPayment: some({
                            destination: new PublicKey(mintFundWallet)
                        }),
                        mintLimit: some({
                            id: 1,
                            limit: 1
                        })
                    }
                })
            )
            .sendAndConfirm(umi, {
                confirm: {
                    commitment: "confirmed",
                }
            });

        window.location.reload();

    } catch (error) {

        ElMessage.error({
            message: error.message,
            type: 'error',
            duration: 5000,
            showClose: true
        });

    }

    ElLoading.service().close();

};

export const mintNftWithGroup = async (candyMachineAddress, mintFundWallet, group, mintProcess, showMintDialog) => {

    try {

        showMintDialog.value = true;

        const umi = getUmi();

        const candyMachine = await fetchCandyMachine(umi, new PublicKey(candyMachineAddress));
        console.log(candyMachine);

        const candyGuard = await fetchCandyGuard(umi, candyMachine.mintAuthority);
        console.log(candyGuard);

        const nftMint = generateSigner(umi);

        if (group.label != 'public') {

            const allowList = group.allowList;

            // Verify the Merkle Proof by specifying which group to select.
            await route(umi, {
                candyMachine: candyMachine.publicKey,
                candyGuard: candyGuard.publicKey,
                guard: "allowList",
                group: some(group.label),
                routeArgs: {
                    path: "proof",
                    merkleRoot: getMerkleRoot(allowList),
                    merkleProof: getMerkleProof(allowList, umi.identity.publicKey)
                }
            }).sendAndConfirm(umi);

            mintProcess.value += 1;

            if (group.label == 'og') {
                var group_id = 1;
            } else if (group.label == 'wl') {
                var group_id = 2;
            }

            var mintArgs = {
                solPayment: some({
                    destination: new PublicKey(mintFundWallet)
                }),
                mintLimit: some({
                    id: group_id,
                    limit: 1
                }),
                allowList: some({
                    merkleRoot: getMerkleRoot(allowList),
                }),
                redeemedAmount: some({ maximum: group.itemsAvailable })
            }

        } else {

            const info = candyGuard.groups.find(g => g.label == 'public');
            const guards = info.guards;

            let mintLimit = guards.mintLimit.value;
            if (!mintLimit) {
                mintLimit = candyGuard.guards.mintLimit.value;
            }

            mintProcess.value += 1;

            var mintArgs = {
                solPayment: some({
                    destination: new PublicKey(mintFundWallet)
                }),
                mintLimit: some(mintLimit)
            }

        }

        await transactionBuilder()
            .add(setComputeUnitLimit(umi, { units: 800_000 }))
            .add(setComputeUnitPrice(umi, { microLamports: 500000 }))
            .add(
                mintV2(umi, {
                    candyMachine: candyMachine.publicKey,
                    nftMint: nftMint,
                    collectionMint: candyMachine.collectionMint,
                    collectionUpdateAuthority: candyMachine.authority,
                    candyGuard: candyGuard.publicKey,
                    tokenStandard: TokenStandard.ProgrammableNonFungible,
                    group: some(group.label),
                    mintArgs: mintArgs
                })
            )
            .sendAndConfirm(umi, {
                confirm: {
                    commitment: "confirmed",
                }
            });

        mintProcess.value += 1;

    } catch (error) {

        ElMessage.error({
            message: error.message,
            type: 'error',
            duration: 5000,
            showClose: true
        });

        mintProcess.value = 0;
        showMintDialog.value = false;

    }

}